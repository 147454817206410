import {
  ConsultationAstroParams,
  ConsultationParams,
  ConsultationRepository,
} from "@/features/consultation/domain/repositories/consultation";
import { HttpGateway } from "@/features/consultation/infrastructure/services/http";

export const consultationDataSourceImplementation = (
  gateway: HttpGateway
): ConsultationRepository => ({
  createConsultationAstro: async <T>(
    params: ConsultationAstroParams
  ): Promise<T> => {
    try {
      if (!params.theme) {
        throw Error("Param theme is required");
      }

      if (!params.title) {
        throw Error("Param title is required");
      }

      if (!params.caption) {
        throw new Error("Param caption is required", {
          cause: {
            field: "caption",
            type: "REQUIRED_FIELD",
          },
        });
      }

      if (!params.duration) {
        throw Error("Param duration is required");
      }

      if (!params.price) {
        throw Error("Param price is required");
      }

      if (!params.type) {
        throw Error("Param type is required");
      }

      return await gateway.post("/prestations", params);
    } catch (e: any) {
      throw Error("Error while creating a consultation", {
        cause: e,
      });
    }
  },

  createConsultation: async <T>(params: ConsultationParams): Promise<T> => {
    try {
      if (!params.title) {
        throw Error("Param title is required");
      }

      if (!params.caption) {
        throw new Error("Param caption is required", {
          cause: {
            field: "caption",
            type: "REQUIRED_FIELD",
          },
        });
      }

      if (!params.duration) {
        throw Error("Param duration is required");
      }

      if (!params.price) {
        throw Error("Param price is required");
      }

      if (!params.type) {
        throw Error("Param type is required");
      }

      return await gateway.post("/prestations", params);
    } catch (e: any) {
      throw Error("Error while creating a consultation", {
        cause: e,
      });
    }
  },
  getConsultations: async <T>(): Promise<T> => {
    try {
      return await gateway.get("/prestations");
    } catch (e) {
      throw Error("Error while retrieving consultations", {
        cause: e,
      });
    }
  },
  getConsultationById: async <T>(id: string): Promise<T> => {
    try {
      return await gateway.get(`/prestations/${id}`);
    } catch (e) {
      throw Error("Error while retrieving consultation", {
        cause: e,
      });
    }
  },
  updateConsultation: async <T>(
    params: Partial<ConsultationParams>,
    id: string
  ): Promise<T> => {
    try {
      return await gateway.patch(`/prestations/${id}`, params);
    } catch (e) {
      throw Error("Error while updating consultation");
    }
  },
  deleteConsultation: async <T>(id: string): Promise<T> => {
    try {
      return await gateway.delete(`/prestations/${id}`);
    } catch (e) {
      throw Error("Error while deleting a consultation", {
        cause: e,
      });
    }
  },
});
