import { CONSULTATION_CALENDAR_CALLBACK } from "@/constants/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { showToast } from "@leeloo/core";

import { CalendarId } from "@/features/consultation/domain/models/booking";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import bookingDataSourceImplementation, {
  CalendarProviderAuthResponse,
  CalendarProviderResponse,
} from "@/features/consultation/infrastructure/datasources/booking";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useCalendarProvidersQuery = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);

  const { data, isLoading, error, isRefetching, refetch } = useQuery({
    queryKey: ["calendars-providers"],
    queryFn: () =>
      booking.getCalendarProviders<AxiosResponse<CalendarProviderResponse>>(),
    select: (data) => data.data,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export const useCalendarProvidersAuthMutation = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);
  const { t } = useTranslation();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: (provider: CalendarId) =>
      booking.getCalendarProviderUrl<
        AxiosResponse<CalendarProviderAuthResponse>
      >({
        provider: provider,
        redirect_uri: `${window.location.origin}/app/catalog/${CONSULTATION_CALENDAR_CALLBACK}`,
      }),
    onSuccess: (response, provider) => {
      localStorage.setItem("provider", provider);
      window.location.assign(response.data.authorization_link);
    },
    onError: () => {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    },
  });

  return {
    getCalendarProviderUrl: (provider: CalendarId) => mutate(provider),
    isSuccess,
    isError,
  };
};

export const useUnsyncCalendarMutation = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isError, isSuccess } = useMutation({
    mutationFn: (provider: CalendarId) =>
      booking.desynchroniseCalendar(provider),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["calendars-providers"],
      });
      showToast({
        title: t("creator_calendar__unsync__toast__title"),
        variant: "success",
      });
    },
    onError: () => {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    },
  });

  return {
    unSynchroniseCalendar: (provider: CalendarId) => mutate(provider),
    isError,
    isSuccess,
  };
};
