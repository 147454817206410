import { useCallback, useContext } from "react";

import { CREATOR_PROFILE } from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ButtonIcon,
  IllustrationName,
  Loader,
  showToast,
} from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import {
  type Consultation,
  ConsultationTheme,
} from "@/features/consultation/domain/models/consultation";
import Card from "@/features/consultation/presentation/components/card";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import ModalConsultation from "@/features/consultation/presentation/components/modal-consultation/ModalConsultation";
import { illustrationMap } from "@/features/consultation/presentation/utils/constants/illustrations";

import { useModalWithData } from "@/hooks/useModalWithData";

import * as styles from "./consultation.css";
import {
  useConsultationByIdQuery,
  useDeleteConsultationMutation,
} from "./viewModel";

export function Consultation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAstro } = useContext(Context).creator;
  const {
    data: consultation,
    isLoading: isLoadingConsultation,
    isError,
    refetch,
    isRefetching: isRefetchingConsultation,
  } = useConsultationByIdQuery();
  const { mutateAsync: deleteConsultationMutation } =
    useDeleteConsultationMutation();

  const onDelete = useCallback(async (id: string) => {
    try {
      await deleteConsultationMutation(id);
      navigate("/catalog/consultations");
      showToast({
        title: t(
          isAstro
            ? "creator_service_consultation_deleted"
            : "NSFW_Live_1_1_creator_delete_success_message"
        ),
        variant: "success",
      });
    } catch (error: any) {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    } finally {
      hideDeleteModal();
    }
  }, []);

  const [showDeleteModal, hideDeleteModal] = useModalWithData(
    (id: string) => () => {
      return (
        <ModalConsultation
          illustration="warning"
          title={t("creator_createservice__closemodal__title")}
          text={t(
            isAstro
              ? "creator_service__delete__content__description"
              : "NSFW_Live_1_1_creator_service__delete__content__description"
          )}
          hideModal={() => hideDeleteModal()}
          mainButtonParams={{
            text: t("creator_service__delete__actions__button"),
            type: "button",
            variant: "destructive",
            onAction: () => onDelete(id),
          }}
        />
      );
    }
  );

  const illustrationAstro = (theme: ConsultationTheme) => {
    return (
      (illustrationMap[theme] as IllustrationName) ||
      ("astro-generic" as IllustrationName)
    );
  };

  if (isLoadingConsultation || isRefetchingConsultation) {
    return <Loader className={styles.loader} spin={true} />;
  }

  return (
    <>
      <MobileContentHeader
        title={t(
          isAstro
            ? "creator_calendar__steptwo___service__header__title"
            : "creator_calendar__stepthree___summary__header__title"
        )}
        withCloseIcon
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
        data-testid="c-header-mobile"
      />
      <ContentHeader
        title={t(
          isAstro
            ? "creator_calendar__steptwo___service__header__title"
            : "creator_calendar__stepthree___summary__header__title"
        )}
        data-testid="c-header-desktop"
      />
      {!consultation || isError ? (
        <Error onClick={() => refetch()} />
      ) : (
        <div className={styles.consultation} data-testid="consultation">
          <Card
            data-testid="c-card"
            consultation={consultation}
            illustrationName={
              isAstro
                ? illustrationAstro(consultation.theme as ConsultationTheme)
                : ("live-not-astro" as IllustrationName)
            }
            children={[
              <ButtonIcon
                className={styles.buttonMobile}
                iconName="delete"
                key="button-delete"
                size="medium"
                variant="destructive"
                data-testid="c-card-delete-button-mobile"
                onPress={() => showDeleteModal(consultation.id)}
              />,
              <Button
                className={styles.button}
                leftIcon="delete"
                key="button-desktop-delete"
                size="medium"
                variant="destructive"
                data-testid="c-card-delete-button"
                onPress={() => showDeleteModal(consultation.id)}
                children={t("delete_button")}
              />,
              <Button
                data-testid="c-card-edit-button"
                size="medium"
                key="button-edit"
                leftIcon="edit-content"
                children={t(
                  "profile_configuration_picture_preview_modal_modify_button"
                )}
                className={styles.editButton}
                onPress={() => {
                  navigate(`/catalog/consultations/edit/${consultation?.id}`);
                }}
              />,
            ]}
          />
        </div>
      )}
    </>
  );
}
