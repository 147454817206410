import { format } from "date-fns";

import { Availabilities } from "@/features/consultation/domain/models/availability";

export const isCurrentMonth = (date: Date) => {
  return (
    date.getFullYear() === new Date().getFullYear() &&
    date.getMonth() === new Date().getMonth()
  );
};

export function sortAvailabilitiesByDay(
  availabilities: Availabilities
): Availabilities {
  const sortedAvailabilities: Availabilities = {
    ...availabilities,
    availabilities: [
      ...availabilities.availabilities.sort((a, b) => a.day - b.day),
    ],
  };

  const firstAvailability = sortedAvailabilities.availabilities.shift();
  sortedAvailabilities.availabilities.push(firstAvailability!);

  return sortedAvailabilities;
}

export const formatHumanLocalizedDate = (date: Date, lang: string) => {
  return new Intl.DateTimeFormat(lang, {
    year: "numeric",
    weekday: "long",
    month: "long",
    day: "numeric",
  }).format(date);
};

export const getDayNameFromNumber = (dayNumber: number, locale = "en-US") => {
  const referenceDate = new Date(Date.UTC(1970, 0, 4 + dayNumber));
  const formatter = new Intl.DateTimeFormat(locale, { weekday: "long" });
  const dayOfWeekName = formatter.format(referenceDate);
  return dayOfWeekName;
};

export const generate15MinuteIntervalsItems = () => {
  const intervals = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, "0");
      const formattedMinute = minute.toString().padStart(2, "0");
      const interval = `${formattedHour}:${formattedMinute}`;
      intervals.push({ label: interval, value: hour * 60 + minute });
    }
  }
  return intervals;
};

export const formatMinutesToStringHour = (minutes: number) => {
  if (typeof minutes === "number") {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const date = new Date(0, 0, 0, hours, mins);

    return format(date, "HH:mm");
  }
  return minutes;
};
