import { useEffect } from "react";

import { CREATOR_PROFILE } from "@/constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Loader, Title } from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import { AvailabilityDTO } from "@/features/consultation/infrastructure/datasources/availability";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import { FormAvailability } from "@/features/consultation/presentation/components/form-availability";
import { availabilitiesSchema } from "@/features/consultation/presentation/components/form-availability/formValidation";

import * as styles from "./availability-setup.css";
import { useAvailabilitiesQuery, useSubmitAvailabilities } from "./viewModel";

export function AvailabilitySetup() {
  const { t } = useTranslation();

  const { data, isLoading, isError, refetch, isRefetching } =
    useAvailabilitiesQuery();
  const { id, enabled, availabilities } = data || {};
  const { onSubmit } = useSubmitAvailabilities();

  const methods = useForm<AvailabilityDTO>({
    criteriaMode: "all",
    defaultValues: data,
    mode: "onSubmit",
    resolver: yupResolver(availabilitiesSchema),
  });

  useEffect(() => {
    methods.reset({
      id,
      enabled,
      availabilities,
    });
  }, [isLoading, data]);

  if (isError) {
    return <Error onClick={() => refetch()} data-testid="availability-error" />;
  }

  return (
    <>
      <MobileContentHeader
        withCloseIcon
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
        title={t(
          "creator_calendar__steptwo___service__header__title_availability"
        )}
        data-testid="availability-setup-header-mobile"
      />
      <ContentHeader
        title={t(
          "creator_calendar__steptwo___service__header__title_availability"
        )}
        data-testid="availability-header-desktop"
      />
      <div
        className={styles.availabilitySetup}
        data-testid="availability-setup"
      >
        <Title
          as="h3"
          className={styles.title}
          data-testid="availbality-setup-title"
        >
          {t(
            "creator_calendar__steptwo___service__heading__title_availability"
          )}
        </Title>
        <FormProvider {...methods}>
          {isLoading || isRefetching ? (
            <Loader
              spin={true}
              className={styles.loader}
              data-testid="availability-loader"
            />
          ) : (
            data && (
              <FormAvailability
                onSubmit={(schedule) => {
                  onSubmit(schedule);
                }}
                submitLabel={t("next_button")}
              />
            )
          )}
        </FormProvider>
      </div>
    </>
  );
}
