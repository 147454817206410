import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { showToast } from "@leeloo/core";

import { BookingsDatesParams } from "@/features/consultation/domain/repositories/booking";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import bookingDataSourceImplementation, {
  BookingDTO,
  BookingDatesDTO,
} from "@/features/consultation/infrastructure/datasources/booking";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const setNextMonth = (
  selectedDate: Date,
  setSelectedDate: (selectedDate: Date) => void,
) => {
  if (selectedDate.getMonth() !== new Date().getMonth() + 3) {
    const nextMonthDate = new Date(selectedDate);

    nextMonthDate.setDate(1);
    nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

    setSelectedDate(nextMonthDate);
  }
};

export const setPreviousMonth = (
  selectedDate: Date,
  setSelectedDate: (selectedDate: Date) => void,
) => {
  const previousMonthDate = new Date(selectedDate);

  previousMonthDate.setDate(1);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  setSelectedDate(previousMonthDate);
};

export const useBookingsDatesQuery = (params: BookingsDatesParams) => {
  const httpClient = httpImplementation();
  const bookingDataSourceImpl = bookingDataSourceImplementation(httpClient);
  const useCase = bookingUseCases(bookingDataSourceImpl);

  return useQuery({
    queryKey: ["bookings-dates", `${params.startDate}-${params.endDate}`],
    queryFn: () =>
      useCase.getBookingsDates<AxiosResponse<BookingDatesDTO>>(params),
    select: (data) => data.data,
  });
};

export const useBookingsByDateQuery = () => {
  const httpClient = httpImplementation();
  const bookingDataSourceImpl = bookingDataSourceImplementation(httpClient);
  const useCase = bookingUseCases(bookingDataSourceImpl);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const getBookingsByDate = async (date: string) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ["bookingsByDate", date],
        queryFn: async () => {
          return useCase.getBookingsByDate<AxiosResponse<BookingDTO[]>>(date);
        },
      });

      return (
        data.data.map((booking) => ({
          ...booking,
          price: {
            ...booking.price,
            value: booking.price.value / 100,
          },
        })) || []
      );
    } catch (error) {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    }
  };

  return { getBookingsByDate };
};

export const useDeleteBookingMutation = () => {
  const httpClient = httpImplementation();
  const bookingDataSourceImpl = bookingDataSourceImplementation(httpClient);
  const useCase = bookingUseCases(bookingDataSourceImpl);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: string; day: string; monthRange: any }) => {
      return useCase.deleteBooking<AxiosResponse<BookingDTO>>(id);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["bookings-dates", `${variables.monthRange.startDate}`],
      });
    },
  });
};
