import { CREATOR_CHAT } from "@/constants/routes";
import formatCurrency from "@/utils/convertPriceCurrency";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Text, Title } from "@leeloo/core";

import { BookingDTO } from "@/features/consultation/infrastructure/datasources/booking";

import * as styles from "./booking-card.css";

export function BookingCard({
  className,
  footerButtons,
  booking,
}: {
  className?: string;
  footerButtons?: React.ReactNode;
  booking: BookingDTO;
}) {
  const { t, i18n } = useTranslation();
  const startDate = new Date(booking.start);
  const endDate = new Date(booking.end);

  const formattedDate = new Intl.DateTimeFormat(i18n.language, {
    weekday: "long",
    month: "long",
    day: "numeric",
  }).format(startDate);

  const startingTime = startDate.toLocaleTimeString(i18n.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const endingTime = endDate.toLocaleTimeString(i18n.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return (
    <div
      className={clsx(styles.bookingCard, className)}
      data-testid="booking-card"
    >
      <div className={styles.bookingCardHeader}>
        <Text data-testid="bc-date">{formattedDate}</Text>
        <Text data-testid="bc-time">
          {startingTime} {t("time_start_end")} {endingTime}
        </Text>
      </div>
      <div className={styles.bookingCardGroup}>
        <Title as="h4" data-testid="bc-title">
          {booking.prestation.title}
        </Title>
        <Title as="h4" data-testid="bc-price">
          {formatCurrency(
            booking.price.value,
            booking.price.currency,
            i18n.language
          )}
        </Title>
      </div>
      <Text data-testid="bc-user-name">
        {t("creator_booking__details__text")}
        <a
          data-testid="bc-user-name-link"
          className={styles.userName}
          href={`${CREATOR_CHAT}/${booking.chatId}`}
        >
          {booking.user.username}
        </a>
      </Text>
      {footerButtons && <div className={styles.footer}>{footerButtons}</div>}
    </div>
  );
}
