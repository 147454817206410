import {
  CONSULTATION_DURATIONS,
  MAX_CAPTION_CHARACTERS,
  MAX_PRICE_VALUE,
  MAX_TITLE_CHARACTERS,
  MIN_CAPTION_CHARACTERS,
  MIN_PRICE_VALUE,
  MIN_TITLE_CHARACTERS,
} from "@/features/consultation/domain/constants";
import {
  ConsultationTheme,
  ConsultationType,
} from "@/features/consultation/domain/models/consultation";
import type {
  ConsultationAstroParams,
  ConsultationParams,
  ConsultationRepository,
} from "@/features/consultation/domain/repositories/consultation";

const checkTheme = (theme: ConsultationTheme) => {
  if (!theme) {
    throw Error("Param theme is required");
  }
};

const checkTitle = (title: string) => {
  if (!title) {
    throw Error("Param title is required");
  }

  if (title == "" || title.length < MIN_TITLE_CHARACTERS) {
    throw Error(`Title should be at least ${MIN_TITLE_CHARACTERS} character`);
  }

  if (title.length > MAX_TITLE_CHARACTERS) {
    throw Error(`Title should be at most ${MAX_TITLE_CHARACTERS} characters`);
  }
};

const checkCaption = (caption: string) => {
  if (!caption) {
    throw Error("Param caption is required", {
      cause: {
        field: "caption",
        type: "REQUIRED_FIELD",
      },
    });
  }

  if (caption == "" || caption.length < MIN_CAPTION_CHARACTERS) {
    throw Error(
      `Caption should be at least ${MIN_CAPTION_CHARACTERS} character`
    );
  }

  if (caption.length > MAX_CAPTION_CHARACTERS) {
    throw Error(
      `Caption should be at most ${MAX_CAPTION_CHARACTERS} characters`
    );
  }
};

const checkDuration = (duration: number) => {
  if (!duration) {
    throw Error("Param duration is required");
  }

  if (
    !CONSULTATION_DURATIONS.some((value) => {
      return duration === value;
    })
  ) {
    throw Error(
      `Duration should be between ${CONSULTATION_DURATIONS[0] / 60} and  ${
        CONSULTATION_DURATIONS[CONSULTATION_DURATIONS.length - 1] / 60
      } minutes`
    );
  }
};

const checkPrice = (price: number) => {
  if (!price) {
    throw Error("Param price is required");
  }

  if (price < MIN_PRICE_VALUE) {
    throw Error(`Price minimum value is ${MIN_PRICE_VALUE}`);
  }

  if (price > MAX_PRICE_VALUE) {
    throw Error(`Price maximum value is ${MAX_PRICE_VALUE}`);
  }
};

const checkType = (type: ConsultationType) => {
  if (!type) {
    throw Error("Param type is required");
  }
};

export const consultationUseCases = (repository: ConsultationRepository) => ({
  createConsultationAstro: async <T>(
    params: ConsultationAstroParams
  ): Promise<T> => {
    try {
      checkTheme(params.theme as ConsultationTheme);
      checkTitle(params.title);
      checkCaption(params.caption);
      checkDuration(params.duration);
      checkPrice(params.price.value);
      checkType(params.type);

      return await repository.createConsultationAstro(params);
    } catch (e: any) {
      throw Error("Error while creating a consultation", {
        cause: e.cause,
      });
    }
  },
  createConsultation: async <T>(params: ConsultationParams): Promise<T> => {
    try {
      checkTitle(params.title);
      checkCaption(params.caption);
      checkDuration(params.duration);
      checkPrice(params.price.value);
      checkType(params.type);

      return await repository.createConsultation(params);
    } catch (e: any) {
      console.log(e);
      throw Error("Error while creating a consultation", {
        cause: e.cause,
      });
    }
  },
  getConsultationById: async <T>(id: string): Promise<T> => {
    try {
      if (!id) {
        throw Error("Consultation id is required");
      }
      return await repository.getConsultationById(id);
    } catch (e: any) {
      throw Error("Error while retrieving a single consultations", {
        cause: e.cause,
      });
    }
  },
  getConsultations: async <T>(): Promise<T> => {
    try {
      return await repository.getConsultations();
    } catch (e: any) {
      throw Error("Error while retrieving consultations", {
        cause: e.cause,
      });
    }
  },
  updateConsultation: async <T>(
    params: Partial<ConsultationParams>,
    id: string
  ): Promise<T> => {
    try {
      (params.title || params.title == "") && checkTitle(params.title);
      (params.caption || params.caption == "") && checkCaption(params.caption);
      params.duration && checkDuration(params.duration);
      params.price && checkPrice(params.price.value);

      if (params.type) {
        throw Error("Consultation type should not be updated");
      }

      return await repository.updateConsultation(params, id);
    } catch (e: any) {
      throw Error("Error while updating a consultation", {
        cause: e.cause,
      });
    }
  },
  deleteConsultation: async <T>(id: string): Promise<T> => {
    try {
      if (!id) {
        throw Error("consultation id is required");
      }

      return await repository.deleteConsultation(id);
    } catch (e: any) {
      throw Error(e);
    }
  },
});

export default consultationUseCases;
