// @typescript-eslint/ban-ts-comment
import { useState, useMemo, useCallback } from "react";
import { useModal } from "react-modal-hook";

export function useModalWithData<T>(
  modal: (data: T) => React.FunctionComponent<any>
) {
  const [data, setData] = useState<T | undefined>(undefined);
  const component = useMemo(() => modal(data!), [data, modal]);
  const [_showModal, hideModal] = useModal(component, [data]);

  const showModal = useCallback(
    (data: T) => {
      setData(data);
      _showModal();
    },
    [_showModal]
  );

  return [showModal, hideModal] as const;
}
