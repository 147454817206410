import { useContext } from "react";

import { CONSULTATION_CONFIGURATION_CREATION } from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Progression, Title } from "@leeloo/core";

export default function SidebarConsultationUpdate() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;

  return (
    <>
      <Heading data-testid="sidebar-content">
        <Illustration
          name="live-alt"
          data-testid="sidebar-content-illustration"
        />
        <Title centered={true} as="h3" data-testid="sidebar-title">
          {isAstro
            ? t("creator_consultations__sidebar__title_desktop")
            : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
        </Title>
      </Heading>
      <Progression
        data-testid="sidebar-progression"
        header={{
          icon: "push",
          title: isAstro
            ? t("creator_consultations__sidebar__titletwo_desktop")
            : t(
                "NSFW_Live_1_1_creator_consultations__sidebar__titletwo_desktop"
              ),
        }}
        steps={[
          {
            label: isAstro
              ? t("creator_consultations__sidebar__textthree_desktop")
              : t(
                  "NSFW_Live_1_1_creator_consultations__sidebar__textthree_desktop"
                ),
            status: "in_progress",
            link: CONSULTATION_CONFIGURATION_CREATION,
          },
        ]}
      />
    </>
  );
}
