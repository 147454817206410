import { Button } from "@leeloo/core";

export function ButtonSyncCalendar({
  children,
  disabled,
  icon,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon: "apple" | "google";
  onClick?: () => void;
}) {
  return (
    <Button
      disabled={disabled}
      leftIcon={icon}
      onPress={onClick}
      variant="secondary"
    >
      {children}
    </Button>
  );
}
