import { Illustration, IllustrationName, Text, Title } from "@leeloo/core";

import * as styles from "./empty-list.css";

type EmptyListProps = {
  illustration: IllustrationName;
  title: string;
  text: string;
  children: React.ReactNode;
};

export default function EmptyList({
  illustration,
  title,
  text,
  children,
  ...props
}: EmptyListProps) {
  return (
    <div className={styles.emptyList} data-testid="cl-empty" {...props}>
      <Illustration
        name={illustration}
        className={styles.illustration}
        data-testid="cl-empty-illustration"
      />
      <Title as="h3" className={styles.title} data-testid="cl-empty-title">
        {title}
      </Title>
      <Text className={styles.text} data-testid="cl-empty-text">
        {text}
      </Text>
      {children}
    </div>
  );
}
