import { isDev } from "@/utils/env";

const env = import.meta.env;

export const environment = {
  amplifyCookieDomain: window.location.hostname,
  apiUrl: isDev ? `/api` : `https://api.${env.VITE_FRONT_API_HOSTNAME}`,
  cdnUrl: `https://cdn.${env.VITE_FRONT_API_HOSTNAME}`,
  cognitoClientId: env.VITE_FRONT_APP_COGNITO_CLIENT_ID,
  cognitoRedirectSignin: `${window.location.origin}/app/oauth2/callback`,
  cognitoRedirectSignout: `${window.location.origin}/app`,
  cognitoRegion: env.VITE_FRONT_APP_COGNITO_REGION,
  cognitoUserPoolDomain: env.VITE_FRONT_APP_COGNITO_USERPOOL_DOMAIN,
  cognitoUserPoolId: env.VITE_FRONT_APP_COGNITO_USERPOOL_ID,
  cookieFirstApiKey: env.VITE_FRONT_APP_COOKIEFIRST_API_KEY,
  currentDomain: window.location.hostname,
  googleTagKey: env.VITE_FRONT_APP_GOOGLE_TAG_KEY,
  hostname: env.VITE_FRONT_APP_HOSTNAME,
  creatorUrl: env.VITE_FRONT_APP_CREATOR_URL,
  googlePlacesApiKey: env.VITE_FRONT_APP_GOOGLE_PLACES_API_KEY,
  origin: window.location.origin,
  rudderStackKey: env.VITE_FRONT_APP_RUDDERSTACK_KEY,
  rudderStackDataplane: env.VITE_FRONT_APP_RUDDERSTACK_DATAPLANE,
  sentryDsn: env.VITE_CREATORS_SENTRY_DSN,
  sentryAuth: env.VITE_SENTRY_AUTH_TOKEN,
  sentryCreatorProject: env.VITE_SENTRY_CREATOR_PROJECT,
  sentryUrl: env.VITE_SENTRY_URL,
};
