import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ButtonIcon, Message, Switch, Text } from "@leeloo/core";

import {
  Availabilities,
  Availability,
  AvailabilityHour,
  DEFAULT_END_VALUE,
  DEFAULT_START_VALUE,
} from "@/features/consultation/domain/models/availability";
import {
  generate15MinuteIntervalsItems,
  getDayNameFromNumber,
} from "@/features/consultation/presentation/utils/date";

import FormFooter from "../form-footer/FormFooter";
import { Range } from "../range/Range";
import * as styles from "./form-availability.css";
import { getErrorMessage } from "./formValidation";

interface FormAvailabilityProps {
  onSubmit: (availabilities: Availabilities, redirect?: boolean) => void;
  submitLabel: string;
}
export type FieldProps = "start" | "end";

export function FormAvailability({
  onSubmit,
  submitLabel,
}: FormAvailabilityProps) {
  const { i18n, t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    clearErrors,
  } = useFormContext<Availabilities>();
  const enabled = watch("enabled");
  const availabilities = watch("availabilities");

  const items = generate15MinuteIntervalsItems();

  const updateField = (
    day: number,
    hourIndex: number,
    newHour: number,
    field: FieldProps,
  ) => {
    const updatedAvailabilities = availabilities.map(
      (availability: Availability) => {
        if (availability.day === day) {
          const updatedHours = availability.hours.map((hour, index) => {
            if (index === hourIndex) {
              return { ...hour, [field]: newHour };
            }
            return hour;
          });
          return { ...availability, hours: updatedHours };
        }
        return availability;
      },
    );
    setValue("availabilities", updatedAvailabilities);
  };

  const addHour = (dayIndex: number, newHour: AvailabilityHour) => {
    const updatedAvailabilities = availabilities.map(
      (day: Availability, index: number) =>
        index === dayIndex
          ? {
              ...day,
              hours: [...day.hours, newHour],
            }
          : day,
    );

    setValue("availabilities", updatedAvailabilities);
  };

  const removeHourFromDay = (dayIndex: number, hourIndex: number) => {
    if (dayIndex !== -1) {
      const updatedHours = availabilities[dayIndex].hours.filter(
        (_, index) => index !== hourIndex,
      );

      const updatedSchedule = availabilities.map(
        (day: Availability, index: number) =>
          index === dayIndex
            ? {
                ...day,
                hours: updatedHours,
              }
            : day,
      );

      setValue("availabilities", updatedSchedule);
    }
  };

  const enableDay = (dayIndex: number) => {
    const updatedSchedule = availabilities.map(
      (day: Availability, index: number) =>
        index === dayIndex
          ? {
              ...day,
              enabled: !day.enabled,
            }
          : day,
    );

    setValue("availabilities", updatedSchedule);
  };

  return (
    <form
      onSubmit={handleSubmit((schedule) => {
        onSubmit(schedule);
      })}
      className={styles.formAvailability}
      data-testid="availability-form"
    >
      {enabled !== undefined && availabilities && (
        <>
          <div className={styles.availabilitySwitcher}>
            <Text data-testid="availability-switcher-text">
              {t("creator_calendar__steptwo___service__text_availability_two")}
            </Text>
            <Switch
              data-testid="availability-switch"
              onClick={() => {
                setValue("enabled", !enabled), clearErrors();
              }}
              checked={enabled}
            />
          </div>
          {errors && (
            <Message
              error={!!getErrorMessage(errors)}
              className={styles.errorMessage}
            >
              {t(getErrorMessage(errors))}
            </Message>
          )}
          {enabled && (
            <div className={styles.schedule} data-testid="availability-ranges">
              {availabilities?.map((day: Availability, dayIndex: number) => (
                <div
                  key={dayIndex}
                  className={styles.daySwitcher}
                  data-testid="availability-day-switcher"
                >
                  <div className={styles.daySwitcherHead}>
                    <Switch
                      onClick={() => enableDay(dayIndex)}
                      checked={day.enabled}
                      data-testid={`availability-day-switch-${dayIndex}`}
                    />
                    <Text
                      className={styles.dayName}
                      data-testid="availability-day-switcher-text"
                    >
                      {getDayNameFromNumber(day.day, i18n.language)}
                    </Text>
                    <ButtonIcon
                      data-testid={`availability-day-switcher-icon-${dayIndex}`}
                      iconName="add"
                      size="small"
                      iconSize="large"
                      variant="simple"
                      className={styles.buttonAdd}
                      onPress={() =>
                        availabilities[dayIndex].hours?.length < 10 &&
                        addHour(dayIndex, {
                          start: DEFAULT_START_VALUE,
                          end: DEFAULT_END_VALUE,
                        })
                      }
                    />
                  </div>
                  {day.enabled && (
                    <div className={styles.hoursList}>
                      {day.hours.map((hour, hourIndex) => {
                        return (
                          <Controller
                            key={hourIndex}
                            name={"availabilities"}
                            control={control}
                            render={() => (
                              <Range
                                id={`${dayIndex}${hourIndex}`}
                                hour={hour}
                                items={items}
                                updateField={(
                                  selectedItem: number,
                                  field: FieldProps,
                                ) =>
                                  updateField(
                                    day.day,
                                    hourIndex,
                                    selectedItem,
                                    field,
                                  )
                                }
                                removeHourFromDay={() =>
                                  availabilities[dayIndex].hours?.length >= 1 &&
                                  removeHourFromDay(dayIndex, hourIndex)
                                }
                                data-testid={`availability-range-${dayIndex}${hourIndex}`}
                              />
                            )}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <FormFooter
        disabled={!availabilities}
        submitLabel={submitLabel}
        data-testid="availability-footer"
        id="availabilities"
      />
    </form>
  );
}
