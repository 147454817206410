import { Key, useContext, useEffect, useMemo, useState } from "react";

import { CREATOR_PROFILE } from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Slider, Text, Title } from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import { BookingDTO } from "@/features/consultation/infrastructure/datasources/booking";
import { trackingImplementation } from "@/features/consultation/infrastructure/services/trackingImplementation";

import { isCurrentMonth } from "../../utils/date";
import * as styles from "./bookings.css";
import { BookingsPanel } from "./containers/bookings-panel/BookingsPanel";
import { useBookingsDatesQuery } from "./viewModel";

export interface OpenedDate {
  date: Date;
  bookings: BookingDTO[];
}

export function Bookings() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState<Key>("future-bookings");

  const trackImpl = trackingImplementation;

  useEffect(() => {
    trackImpl.page_view("creator_page_next_consultations");
  }, []);

  const monthRange = useMemo(
    () =>
      selectedTab === "future-bookings"
        ? {
            // startDate is either the first day of the month or the current day if the selected month is the current month (api can't take past dates atm)
            startDate: isCurrentMonth(selectedMonth)
              ? format(new Date(), "yyyy-MM-dd")
              : format(
                  new Date(
                    selectedMonth.getFullYear(),
                    selectedMonth.getMonth(),
                    1
                  ),
                  "yyyy-MM-dd"
                ),
            // We find the last day of the month for endDate
            endDate: format(
              new Date(
                selectedMonth.getFullYear(),
                selectedMonth.getMonth() + 1,
                0
              ),
              "yyyy-MM-dd"
            ),
          }
        : {
            startDate: format(
              new Date(
                selectedMonth.getFullYear(),
                selectedMonth.getMonth(),
                1
              ),
              "yyyy-MM-dd"
            ),
            endDate: isCurrentMonth(selectedMonth)
              ? format(
                  new Date().setDate(new Date().getDate() - 1),
                  "yyyy-MM-dd"
                )
              : format(
                  new Date(
                    selectedMonth.getFullYear(),
                    selectedMonth.getMonth() + 1,
                    0
                  ),
                  "yyyy-MM-dd"
                ),
          },
    [selectedMonth, selectedTab]
  );

  const {
    data: bookingsDates,
    isLoading: isLoadingBookingsDates,
    isError: isErrorBookingsDates,
    refetch: refetchBookingDates,
    isRefetching: isRefetchingBookingsDates,
  } = useBookingsDatesQuery(monthRange);

  return (
    <>
      <ContentHeader
        title={
          isAstro
            ? t("creator_booking__heading__title")
            : t("NSFW_live_creator_booking__heading__title")
        }
        data-testid="b-header-desktop"
      />
      <MobileContentHeader
        title={
          isAstro
            ? t("creator_booking__header__title")
            : t("NSFW_live_creator_booking__heading__title")
        }
        withCloseIcon
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
        data-testid="b-header-mobile"
      />
      <div className={styles.bookings} data-testid="bookings">
        <Title as="h3" className={styles.title} data-testid="b-title">
          {t("creator_booking__heading__title")}
        </Title>
        <Text className={styles.text} data-testid="b-text">
          {t("creator_booking__heading__description")}
        </Text>
        <Slider
          onClick={(key) => {
            setSelectedTab(key);
          }}
          tabs={[
            {
              title: t("creator_booking__details__future"),
              panel: (
                <BookingsPanel
                  past={false}
                  monthRange={monthRange}
                  bookingsDates={bookingsDates}
                  refetchBookingDates={refetchBookingDates}
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  isLoading={
                    isLoadingBookingsDates || isRefetchingBookingsDates
                  }
                  isError={isErrorBookingsDates}
                />
              ),
              key: "future-bookings",
            },
            {
              title: t("creator_booking__details__past"),
              panel: (
                <BookingsPanel
                  past={true}
                  bookingsDates={bookingsDates}
                  refetchBookingDates={refetchBookingDates}
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  isLoading={
                    isLoadingBookingsDates || isRefetchingBookingsDates
                  }
                  isError={isErrorBookingsDates}
                />
              ),
              key: "past-bookings",
            },
          ]}
        />
      </div>
    </>
  );
}
