import { useContext } from "react";

import { Context } from "@/contexts/creator/Creator";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ButtonIcon, Text } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";

import {
  Calendar,
  CalendarId,
} from "@/features/consultation/domain/models/booking";
import { trackingImplementation } from "@/features/consultation/infrastructure/services/trackingImplementation";
import ModalConsultation from "@/features/consultation/presentation/components/modal-consultation/ModalConsultation";
import { ButtonSyncCalendar } from "@/features/consultation/presentation/pages/CalendarSetup/containers/button-sync-calendar";

import { useModalWithData } from "@/hooks/useModalWithData";

import * as styles from "./calendar-sync.css";

interface CalendarSyncProps {
  calendar: Calendar | null;
  className?: string;
  getCalendarProviderUrl: (provider: CalendarId) => void;
  unSynchroniseCalendar: (provider: CalendarId) => void;
}

export function CalendarSync({
  calendar,
  className,
  getCalendarProviderUrl,
  unSynchroniseCalendar,
  ...rest
}: CalendarSyncProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const trackImpl = trackingImplementation;
  const { isAstro } = useContext(Context).creator;

  const setPageRedirection = () => {
    localStorage.setItem("redirect_uri", location.pathname);

    if (location?.state?.locationPage === "list") {
      localStorage.setItem("redirect_uri", "/catalog/consultations");
    }
  };

  const [showDeleteModal, hideDeleteModal] = useModalWithData(
    (id: CalendarId) => () => {
      return (
        <ModalConsultation
          illustration="warning"
          title={t("creator_createservice__closemodal__title")}
          text={
            isAstro
              ? t("creator_calendar__unsync__description", {
                  provider: calendar?.provider_id,
                })
              : t(
                  "NSFW_Live_1_1_creator_calendar__unsync__content__description"
                )
          }
          hideModal={() => hideDeleteModal()}
          mainButtonParams={{
            text: t("creator_calendar__unsync__button"),
            variant: "destructive",
            onAction: async () => {
              unSynchroniseCalendar(id);
              hideDeleteModal();
            },
          }}
        />
      );
    }
  );

  return (
    <>
      {calendar ? (
        <div
          className={clsx(styles.calendarSync, className)}
          data-testid="calendar-sync"
          {...rest}
        >
          <div className={styles.container}>
            <div className={styles.google}>
              <Text
                className={styles.providerIcon}
                data-testid="calendar-sync-icon"
              >
                <Icon
                  className={styles.icon}
                  name={calendar.provider_id === "google" ? "google" : "apple"}
                />
              </Text>
              <Text
                weight="medium"
                variant="large"
                data-testid="calendar-sync-name"
              >
                {t("creator_calendar__title", {
                  provider:
                    calendar.provider_id === "icloud" ? "iCloud" : "Google",
                })}
              </Text>
            </div>
            <div className={styles.tag}>
              <Text
                data-testid="calendar-sync-state-text"
                className={styles.tagText}
              >
                {t("creator_calendar__stepone___sync__state")}
              </Text>
              <Icon
                name="check"
                className={styles.tagIcon}
                data-testid="calendar-sync-state-icon"
              />
            </div>
          </div>
          <div className={styles.container}>
            <Text data-testid="calendar-sync-email">{calendar.email}</Text>
            <ButtonIcon
              data-testid="calendar-sync-delete-button"
              size="medium"
              iconSize="medium"
              iconName="delete"
              onPress={() => showDeleteModal(calendar.provider_id)}
            />
          </div>
        </div>
      ) : (
        <div className={styles.syncButtons}>
          <ButtonSyncCalendar
            data-testid="cs-button-google"
            children={t("creator_calendar__bottombutton__google")}
            icon="google"
            onClick={() => {
              trackImpl.track("creator_track_sync_google", "");
              setPageRedirection();
              getCalendarProviderUrl("google");
            }}
          />
          <ButtonSyncCalendar
            data-testid="cs-button-apple"
            children={t("creator_calendar__bottombutton__icloud")}
            icon="apple"
            onClick={() => {
              trackImpl.track("creator_track_sync_icloud", "");
              setPageRedirection();
              getCalendarProviderUrl("icloud");
            }}
          />
        </div>
      )}
    </>
  );
}
