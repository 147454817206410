import { useContext } from "react";

import {
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_CALENDAR_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Progression, Title } from "@leeloo/core";

import * as styles from "./sidebar-availability.css";

export default function SidebarAvailability() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;

  return (
    <Heading data-testid="sidebar-availability">
      <Illustration
        name="live-alt"
        data-testid="sidebar-availability-illustration"
      />
      <Title
        centered={true}
        as="h3"
        data-testid="sidebar-availability-title"
        className={styles.sidebarTitle}
      >
        {isAstro
          ? t("creator_consultations__sidebar__title_desktop")
          : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
      </Title>
      <Progression
        header={{
          icon: "push",
          title: isAstro
            ? t("creator_consultations__sidebar__titletwo_desktop")
            : t(
                "NSFW_Live_1_1_creator_consultations__sidebar__titletwo_desktop"
              ),
        }}
        steps={[
          {
            label: t("creator_consultations__sidebar__text_desktop"),
            status: "success",
            link: CONSULTATION_CALENDAR_SETUP,
          },
          {
            label: t("creator_consultations__sidebar_timeslots"),
            status: "in_progress",
            link: CONSULTATION_AVAILABILITY_SETUP,
          },
          {
            label: isAstro
              ? t("creator_consultations__sidebar__texttwo_desktop")
              : t(
                  "NSFW_Live_1_1_creator_consultations__sidebar__texttwo_desktop"
                ),
            status: "disabled",
            link: CONSULTATION_CONFIGURATION_CREATION,
          },
        ]}
        data-testid="sidebar-availability-progression"
      />
    </Heading>
  );
}
