export enum ConsultationTheme {
  ASTROLOGY = "theme_select_astrology",
  TAROLOGY = "theme_select_tarology",
  MEDIUM_CLAIRVOYANCE = "theme_select_medium_clairvoyance",
  NUMEROLOGY = "theme_select_numerology",
  ENERGY_SPECIALIST = "theme_select_energy_specialist",
  HOLISTIC_SPECIALIST = "theme_select_holistic_therapy",
  OTHERS = "theme_select_others",
}

export enum ConsultationType {
  LIVE_ONE_ONE = "live_one_one",
}

export interface Consultation {
  id: string;
  theme?: ConsultationTheme;
  title: string;
  caption: string;
  duration: number;
  price: {
    value: number;
    currency: string;
  };
  type: ConsultationType;
}
