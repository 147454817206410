import { useState } from "react";

import { useTranslation } from "react-i18next";

import {
  Button,
  ButtonType,
  IllustrationName,
  Modal,
  Text,
  Title,
} from "@leeloo/core";

import { Illustration } from "@/components/illustration/Illustration";

import * as styles from "./modal-consultation.css";

interface ModalConsultationProps {
  illustration: IllustrationName;
  title: string;
  text: string | React.ReactElement;
  hideModal: () => void;
  mainButtonParams: {
    text: string;
    variant: ButtonType;
    type?: "submit" | "button" | "reset" | undefined;
    onAction: () => Promise<void>;
  };
}

export default function ModalConsultation({
  illustration,
  title,
  text,
  mainButtonParams,
  hideModal,
}: ModalConsultationProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal hideModal={hideModal} showClose={true}>
      <div className={styles.modal} data-testid="consultation-modal">
        <Illustration
          name={illustration}
          className={styles.illustration}
          data-testid="cm-illustration"
        />
        <Title className={styles.title} as="h3" data-testid="cm-title">
          {title}
        </Title>
        <Text data-testid="cm-text" className={styles.text}>
          {text}
        </Text>
        <Button
          className={styles.mainButton}
          data-testid="cm-button-main"
          variant={mainButtonParams.variant}
          type={mainButtonParams.type || "button"}
          role={mainButtonParams.type || "button"}
          onPress={async () => {
            setIsLoading(true);
            await mainButtonParams.onAction();
            setIsLoading(false);
            hideModal();
          }}
          key="delete"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {mainButtonParams.text}
        </Button>
        <Button
          className={styles.cancelButton}
          data-testid="cm-button-cancel"
          variant="secondary"
          onPress={hideModal}
          key="cancel"
        >
          {t("cancel_button")}
        </Button>
      </div>
    </Modal>
  );
}
