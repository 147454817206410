import React, { useContext } from "react";

import {
  CALENDAR,
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Progression, Title } from "@leeloo/core";

export default function SidebarCalendarSetup() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;

  return (
    <>
      <Heading data-testid="sidebar-calendar-setup">
        <Illustration
          name="live-alt"
          data-testid="sidebar-calendar-setup-illustration"
        />
        <Title
          centered={true}
          as="h3"
          data-testid="sidebar-calendar-setup-title"
        >
          {isAstro
            ? t("creator_consultations__sidebar__title_desktop")
            : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
        </Title>
      </Heading>
      <Progression
        data-testid="sidebar-calendar-setup-progression"
        header={{
          icon: "push",
          title: t(
            isAstro
              ? "creator_consultations__sidebar__titletwo_desktop"
              : "NSFW_Live_1_1_creator_consultations__sidebar__titletwo_desktop"
          ),
        }}
        steps={[
          {
            label: t("creator_consultations__sidebar__text_desktop"),
            status: "in_progress",
            link: CALENDAR,
          },
          {
            label: t("creator_consultations__sidebar_timeslots"),
            status: "disabled",
            link: CONSULTATION_AVAILABILITY_SETUP,
          },
          {
            label: t(
              isAstro
                ? "creator_consultations__sidebar__texttwo_desktop"
                : "NSFW_Live_1_1_creator_consultations__sidebar__texttwo_desktop"
            ),
            status: "disabled",
            link: CONSULTATION_CONFIGURATION_CREATION,
          },
        ]}
      />
    </>
  );
}
