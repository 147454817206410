export default function formatCurrency(
  price: number,
  currency: string,
  language: string
) {
  const decimalCount = (price.toString().split(".")[1] || "").length;

  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: decimalCount > 0 ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(price);
}
