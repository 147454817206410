import { useCallback, useContext, useEffect, useState } from "react";

import {
  CONSULTATIONS_LIST,
  CONSULTATION_CONFIGURATION_CREATION,
  CONSULTATION_CONFIRMATION,
  CREATOR_PROFILE,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBeforeUnload, useLocation, useNavigate } from "react-router-dom";

import { Loader, showToast } from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import { ConsultationType } from "@/features/consultation/domain/models/consultation";
import {
  ConsultationMutationParams,
  ConsultationParams,
} from "@/features/consultation/domain/repositories/consultation";
import { ConsultationState } from "@/features/consultation/infrastructure/datastores/store/store";
import { trackingImplementation } from "@/features/consultation/infrastructure/services/trackingImplementation";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import FormFooter from "@/features/consultation/presentation/components/form-footer/FormFooter";
import { FormSetup } from "@/features/consultation/presentation/components/form-setup/FormSetup";

import { consultationSetup, consultationSetupAstro } from "./formValidation";
import * as styles from "./setup.css";
import {
  useCreateConsultationMutation,
  useCreatorMeQuery,
  useStore,
} from "./viewModel";

export type FormSetupData = Omit<
  ConsultationState,
  "id" | "consultationStatus"
>;

export const Setup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAstro } = useContext(Context).creator;
  const trackImpl = trackingImplementation;
  const [isLoading, setLoading] = useState(false);

  const {
    theme,
    title,
    caption,
    duration,
    price,
    updateConsultation,
    resetStore,
  } = useStore();
  const createConsultationMutation = useCreateConsultationMutation();

  const {
    data: creator,
    isLoading: isLoadingCreator,
    isError,
    refetch,
    isRefetching: isRefetchingCreator,
  } = useCreatorMeQuery();

  useBeforeUnload((event) => {
    event.preventDefault();
    event.returnValue = true;
  });

  const onBlur = useCallback((values: Partial<FormSetupData>) => {
    updateConsultation(values);
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);

      const { data: newConsultation } =
        await createConsultationMutation.mutateAsync({
          theme,
          title,
          caption,
          duration,
          price: {
            value: price && price * 100,
            currency: creator?.currency,
          },
          type: ConsultationType.LIVE_ONE_ONE,
          isAstro,
        } as ConsultationMutationParams);

      resetStore();

      if (
        location.pathname === `/catalog/${CONSULTATION_CONFIGURATION_CREATION}`
      ) {
        navigate(`/catalog/${CONSULTATION_CONFIRMATION}`, {
          replace: true,
          state: { consultationId: newConsultation.id },
        });
      } else {
        navigate(`/catalog/${CONSULTATIONS_LIST}`);
      }

      showToast({
        title: isAstro
          ? t("creator_service_consultation_added")
          : t("NSFW_live_creator_service_consultation_added"),
        variant: "success",
      });
    } catch (error: any) {
      if (error?.cause?.response?.data?.error?.message) {
        showToast({
          description: t(error.cause.response.data.error.message),
          title: t("technical_error_toast_title"),
          variant: "error",
        });
      } else {
        showToast({
          description: t("technical_error_toast"),
          title: t("technical_error_toast_title"),
          variant: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  }, [theme, title, caption, duration, price]);

  const methods = useForm<FormSetupData>({
    criteriaMode: "all",
    defaultValues: {
      theme,
      title,
      caption,
      duration,
      price,
    },
    mode: "onSubmit",
    resolver: yupResolver(isAstro ? consultationSetupAstro : consultationSetup),
  });

  useEffect(() => {
    trackImpl.page_view("creator_page_step_2_consultation");
  }, []);

  if (isLoadingCreator || isRefetchingCreator) {
    return <Loader spin={true} />;
  }

  return (
    <>
      <ContentHeader
        className={styles.contentHeader}
        data-testid="consultation-setup-desktop-header"
        title={
          isAstro
            ? t("creator_calendar__steptwo___service__header__title")
            : t(
                "NSFW_Live_1_1_creator_calendar__steptwo___service__heading__title"
              )
        }
      />
      <MobileContentHeader
        data-testid="consultation-setup-mobile-header"
        withCloseIcon
        title={
          isAstro
            ? t("creator_calendar__steptwo___service__header__title")
            : t(
                "NSFW_Live_11_creator_calendar__steptwo___service__header__title"
              )
        }
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
      />
      {!creator || isError ? (
        <Error
          data-testid="consultation-creation-error"
          onClick={() => refetch()}
        />
      ) : (
        <FormProvider {...methods}>
          <FormSetup
            children={
              <FormFooter
                submitLabel={t("next_button")}
                isLoading={isLoading}
              />
            }
            isAstro={isAstro}
            currency={creator?.currency || "EUR"}
            onBlur={onBlur}
            onSubmit={onSubmit}
          />
        </FormProvider>
      )}
    </>
  );
};
