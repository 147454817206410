import { useEffect } from "react";

import { CREATOR_PROFILE } from "@/constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, Cartridge, Loader, Text, Title } from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import {
  CalendarId,
  Calendar as CalendarProps,
  CalendarStatus,
} from "@/features/consultation/domain/models/booking";
import { AvailabilityDTO } from "@/features/consultation/infrastructure/datasources/availability";
import { CalendarSync } from "@/features/consultation/presentation/components/calendar-sync/CalendarSync";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import { FormAvailability } from "@/features/consultation/presentation/components/form-availability";

import { availabilitiesSchema } from "../../components/form-availability/formValidation";
import * as styles from "./calendar.css";
import {
  useAvailabilitiesQuery,
  useCalendarProvidersAuthMutation,
  useCalendarProvidersQuery,
  useSubmitAvailabilities,
  useUnsyncCalendarMutation,
} from "./viewModel";

export function Calendar() {
  const { t } = useTranslation();

  const {
    data: providers,
    error,
    isLoading,
    refetch,
    isRefetching,
  } = useCalendarProvidersQuery();
  const { onSubmit } = useSubmitAvailabilities();

  const {
    data: availabilitiesResponse,
    isLoading: isLoadingAvailabilities,
    error: errorAvailabilities,
    isRefetching: isRefetchingAvailabilities,
    refetch: refetchAvailabilities,
  } = useAvailabilitiesQuery();

  const { id, enabled, availabilities } = availabilitiesResponse || {};

  const methods = useForm<AvailabilityDTO>({
    criteriaMode: "all",
    defaultValues: availabilitiesResponse,
    mode: "onSubmit",
    resolver: yupResolver(availabilitiesSchema),
  });

  useEffect(() => {
    methods.reset({
      id,
      enabled,
      availabilities,
    });
  }, [isLoading, availabilitiesResponse]);

  const calendar =
    (providers &&
      providers.providers.find(
        (provider: CalendarProps) => provider.status === CalendarStatus.SYNCED
      )) ||
    null;

  const { getCalendarProviderUrl } = useCalendarProvidersAuthMutation();
  const { unSynchroniseCalendar } = useUnsyncCalendarMutation();

  if (
    isLoading ||
    isRefetching ||
    isLoadingAvailabilities ||
    isRefetchingAvailabilities
  ) {
    return (
      <Loader
        spin={true}
        className={styles.loader}
        data-testid="calendar-loader"
      />
    );
  }

  if (error) {
    return <Error onClick={() => refetch()} data-testid="calendar-error" />;
  }

  if (errorAvailabilities) {
    return (
      <Error
        onClick={() => refetchAvailabilities()}
        data-testid="calendar-availabilities-error"
      />
    );
  }

  return (
    <>
      <MobileContentHeader
        withCloseIcon
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
        title={t("creator_calendar__sync__header__title__availability")}
        data-testid="calendar-header-mobile"
      />
      <ContentHeader
        title={t("creator_calendar__sync__header__title__availability")}
        data-testid="calendar-header-desktop"
      />
      <div className={styles.calendar} data-testid="calendar">
        <Title as="h3" className={styles.title} data-testid="calendar-title">
          {t("creator_calendar__sync__heading__title_availability")}
        </Title>
        <Text className={styles.text} data-testid="calendar-text">
          {t("creator_calendar__sync__description__availability")}
        </Text>
        <Cartridge
          data-testid="calendar-cartridge"
          title={t("creator_calendar__cartouche__title")}
          description={t("creator_calendar__cartouche__description")}
          className={styles.cartridge}
          buttons={[
            <Button
              size="small"
              variant="tertiary"
              key="button_calendar"
              onPress={() =>
                window.open(
                  "https://www.youtube.com/watch?v=bQiuj0m3CT8&ab_channel=MYM",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              {t("creator_calendar__cartouche__button")}
            </Button>,
          ]}
        />
        {calendar && (
          <Text className={styles.subtitle}>
            {t("creator_calendar__sync__description__two__availability")}
          </Text>
        )}
        {providers && (
          <CalendarSync
            calendar={calendar}
            className={styles.calendarSync}
            data-testid="calendar-sync"
            unSynchroniseCalendar={(provider: CalendarId) =>
              unSynchroniseCalendar(provider)
            }
            getCalendarProviderUrl={(provider: CalendarId) =>
              getCalendarProviderUrl(provider)
            }
          />
        )}
        {calendar && (
          <>
            <Text className={styles.subtitle}>
              {t(
                "creator_calendar__sync__tes_plages_horaires_de_disponibilité"
              )}
            </Text>
            <FormProvider {...methods}>
              {availabilitiesResponse && (
                <FormAvailability
                  data-testid="calendar-form-availability"
                  onSubmit={(availabilities: AvailabilityDTO) => {
                    onSubmit(availabilities);
                  }}
                  submitLabel={t(
                    "creator_calendar__steptwo___service__save_changes"
                  )}
                />
              )}
            </FormProvider>
          </>
        )}
      </div>
    </>
  );
}
