import { useContext } from "react";

import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Text, Title } from "@leeloo/core";

import * as styles from "./sidebar-bookings.css";

export default function SidebarBookings() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;

  return (
    <Heading data-testid="sidebar-bookings">
      <Illustration name="live-alt" data-testid="sidebar-bl-illustration" />
      <Title centered={true} as="h3" data-testid="sidebar-bl-title">
        {isAstro
          ? t("creator_consultations__sidebar__title_desktop")
          : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
      </Title>
      <Text className={styles.text}>
        {isAstro
          ? t("creator_consultations__sidebar__description_desktop")
          : t("NSFW_live_creator_consultations_sidebar_description_desktop")}
      </Text>
    </Heading>
  );
}
