import { useEffect } from "react";
import type { FieldErrors } from "react-hook-form";

export function useScrollToError(errors: FieldErrors) {
  useEffect(() => {
    for (const error of Object.keys(errors)) {
      if (error) {
        document
          .getElementById(error)
          ?.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      return;
    }
  }, [errors]);
}
