import { useCallback, useEffect, useState } from "react";

import { CONSULTATIONS_LIST, CREATOR_PROFILE } from "@/constants/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useBeforeUnload, useNavigate } from "react-router-dom";

import { Loader, showToast } from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import { ConsultationParams } from "@/features/consultation/domain/repositories/consultation";
import { ConsultationState } from "@/features/consultation/infrastructure/datastores/store/store";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import FormFooter from "@/features/consultation/presentation/components/form-footer/FormFooter";
import { FormSetup } from "@/features/consultation/presentation/components/form-setup/FormSetup";
import ModalConsultation from "@/features/consultation/presentation/components/modal-consultation/ModalConsultation";

import { useModalWithData } from "@/hooks/useModalWithData";

import { consultationSetup } from "./formValidation";
import * as styles from "./update.css";
import {
  useCreatorMeQuery,
  useGetConsultationByIdQuery,
  useUpdateConsultation,
} from "./viewModel";

export type FormSetupData = Omit<
  ConsultationState,
  "id" | "consultationStatus"
>;

export const Update = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const {
    data: consultation,
    isLoading: isLoadingConsultation,
    isError: isErrorConsultation,
    refetch: refetchConsultation,
  } = useGetConsultationByIdQuery();
  const { mutateAsync: updateConsultationMutation } = useUpdateConsultation();
  const {
    data: creator,
    isError: isErrorCreator,
    refetch: refetchCreator,
    isRefetching: isRefetchingCreator,
  } = useCreatorMeQuery();

  const isAstro = creator?.category === "astro";

  useBeforeUnload((event) => {
    event.preventDefault();
    event.returnValue = true;
  });

  const onSubmit = useCallback(
    async (values: FieldValues) => {
      try {
        setLoading(true);

        const dirtyFields = Object.keys(methods.formState.dirtyFields);
        const dirtyValues = dirtyFields.reduce((acc: FieldValues, field) => {
          acc[field] = values[field];

          return acc;
        }, {});
        await updateConsultationMutation({
          payload: {
            ...dirtyValues,
            ...(dirtyValues.price && {
              price: {
                value: dirtyValues.price * 100,
                currency: creator?.currency || "EUR",
              },
            }),
          } as Partial<ConsultationParams>,
        });

        navigate(`/catalog/${CONSULTATIONS_LIST}`);
        showToast({
          title: t("creator_service_consultation_modified"),
          variant: "success",
        });
      } catch (error: any) {
        if (error?.cause?.response?.data?.error?.message) {
          showToast({
            description: t(error.cause.response.data.error.message),
            title: t("technical_error_toast_title"),
            variant: "error",
          });
        } else {
          showToast({
            description: t("technical_error_toast"),
            title: t("technical_error_toast_title"),
            variant: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [consultation, creator]
  );

  const [showUpdateModal, hideUpdateModal] = useModalWithData(
    (payload: FieldValues) => () => {
      return (
        <ModalConsultation
          illustration="warning"
          title={t("creator_createservice__closemodal__title")}
          text={
            isAstro
              ? t("creator_service__editconfirm__content__description")
              : t("NSFW_Live_1_1_creator_service_edit_confirm")
          }
          hideModal={() => hideUpdateModal()}
          mainButtonParams={{
            text: t("creator_service__edit__bottombar__button"),
            type: "submit",
            variant: "primary",
            onAction: () => onSubmit(payload),
          }}
        />
      );
    }
  );

  const methods = useForm<FormSetupData>({
    criteriaMode: "all",
    defaultValues: {
      theme: consultation?.theme,
      title: consultation?.title,
      caption: consultation?.caption,
      duration: consultation?.duration,
      price: consultation?.price?.value as number,
    },
    mode: "onBlur",
    resolver: yupResolver(consultationSetup),
  });

  useEffect(() => {
    methods.reset({
      theme: consultation?.theme,
      title: consultation?.title,
      caption: consultation?.caption,
      duration: consultation?.duration,
      price: consultation?.price?.value as number,
    });
  }, [isLoadingConsultation, consultation]);

  if (isLoadingConsultation || isRefetchingCreator) {
    return <Loader spin={true} className={styles.loader} />;
  }

  return (
    <>
      <ContentHeader
        className={styles.contentHeader}
        data-testid="consultation-update-desktop-header"
        withButtonBack
        title={
          isAstro
            ? t("creator_calendar__steptwo___service__header__title")
            : t(
                "NSFW_Live_1_1_creator_calendar__steptwo___service__heading__title"
              )
        }
      />
      <MobileContentHeader
        data-testid="consultation-update-mobile-header"
        withCloseIcon
        title={
          isAstro
            ? t("creator_calendar__steptwo___service__header__title")
            : t(
                "NSFW_Live_11_creator_calendar__steptwo___service__header__title"
              )
        }
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
      />
      {!consultation || isErrorConsultation || isErrorCreator ? (
        <Error
          onClick={() => {
            refetchConsultation();
            refetchCreator();
          }}
        />
      ) : (
        <FormProvider {...methods}>
          <FormSetup
            children={
              <FormFooter
                submitLabel={t("next_button")}
                isLoading={isLoading}
                onClick={() =>
                  !Object.keys(methods.formState.errors).length &&
                  showUpdateModal(methods.getValues())
                }
              />
            }
            currency={creator?.currency || "EUR"}
            isAstro={isAstro}
            onSubmit={onSubmit}
          />
        </FormProvider>
      )}
    </>
  );
};
