import * as yup from "yup";

import { checkOverlappingRange } from "@/features/consultation/domain/use-cases/availability";

export const getErrorMessage = (errors: any): string => {
  if (errors?.message) {
    return errors.message;
  }

  if (Array.isArray(errors)) {
    for (const error of errors) {
      const message = getErrorMessage(error);
      if (message) {
        return message;
      }
    }
  }

  if (typeof errors === "object" && errors !== null) {
    for (const key of Object.keys(errors)) {
      const message = getErrorMessage(errors[key]);
      if (message) {
        return message;
      }
    }
  }

  return "";
};

export const availabilitiesSchema = yup.object().shape({
  id: yup.string().required(),
  enabled: yup.boolean().required(),
  availabilities: yup
    .array()
    .of(
      yup.object().shape({
        enabled: yup.boolean().required(),
        day: yup.number().required(),
        hours: yup
          .array()
          .of(
            yup.object().shape({
              start: yup.number().required(),
              end: yup.number().required(),
            })
          )
          .when("enabled", {
            is: true,
            then: yup
              .array()
              .of(
                yup
                  .object()
                  .shape({
                    start: yup.number().required(),
                    end: yup.number().required(),
                  })
                  .test(
                    "is-end-greater",
                    "End time must be greater than start time",
                    function (value) {
                      if (
                        value &&
                        value.start !== undefined &&
                        value.end !== undefined
                      ) {
                        return value.end > value.start;
                      }
                      return false;
                    }
                  )
              )
              .min(1, "error_availability_slots_minimum")
              .max(10, "error_availability_slots_maximum"),
          }),
      })
    )
    .when("enabled", {
      is: true,
      then: yup
        .array()
        .test(
          "overlapping",
          "error_availability_slots_overlap",
          function (value: any) {
            return !checkOverlappingRange(value);
          }
        ),
    }),
});
