import {
  ANTI_EXPLOITATION,
  CHILD_PROTECTION,
  CONTENT_POLICY,
  COOKIES,
  LEGAL_MENTIONS,
  ONLINE_HARASSMENT,
  PLATFORM_STATUS,
  PRIVACY,
  SUPPORT,
  SUPPORT_FORM_CREATOR_ID,
  TERMS_OF_SALE,
  TERMS_OF_USE,
} from "@/constants/routes";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { IconName, Link } from "@leeloo/core";

import { Icon } from "@/components/icon/Icon";

import * as styles from "./sidebar-desktop.css";

export function SidebarDesktop({
  className,
  withFooter,
  children,
  footerLinksArray,
  socialLinksArray,
  ...props
}: {
  className?: string;
  withFooter?: boolean;
  children?: React.ReactNode;
  footerLinksArray?: { href: string; name: string }[];
  socialLinksArray?: { href: string; name: IconName }[];
}) {
  const { t } = useTranslation();

  footerLinksArray = footerLinksArray || [
    { name: t("sidebar_link_about"), href: "https://corporate.mym.fans/" },
    { name: t("sidebar_link_need_help"), href: `${SUPPORT}` },
    { name: t("sidebar_link_cookies"), href: `${COOKIES}` },
    { name: t("sidebar_link_privacy"), href: `${PRIVACY}` },
    { name: t("sidebar_link_terms_of_use"), href: `${TERMS_OF_USE}` },
    { name: t("sidebar_link_cgs"), href: `${TERMS_OF_SALE}` },
    { name: t("sidebar_link_cyber"), href: `${ONLINE_HARASSMENT}` },
    { name: t("sidebar_link_incidents"), href: `${PLATFORM_STATUS}` },
    { name: t("sidebar_link_legal"), href: `${LEGAL_MENTIONS}` },
    { name: t("sidebar_link_child_protection"), href: `${CHILD_PROTECTION}` },
    { name: t("sidebar_link_anti_explotation"), href: `${ANTI_EXPLOITATION}` },
    { name: t("sidebar_link_content_policy"), href: `${CONTENT_POLICY}` },
    {
      name: t("sidebar_link_reporting"),
      href: `${SUPPORT}/requests/new?ticket_form_id=${SUPPORT_FORM_CREATOR_ID}`,
    },
  ];

  socialLinksArray = socialLinksArray || [
    { name: "facebook", href: "https://www.facebook.com/mym.fans" },
    { name: "x", href: "https://twitter.com/mym_off" },
    { name: "instagram", href: "https://www.instagram.com/mym/" },
    { name: "document-pen", href: "https://blog.mym.com/" },
  ];

  return (
    <aside
      className={clsx(styles.sidebar, className)}
      data-testid="sidebar"
      {...props}
    >
      <div className={styles.header}>{children}</div>
      {withFooter && (
        <div className={styles.footer}>
          <div className={styles.linksList}>
            {footerLinksArray.map((link, index) => (
              <Link
                key={index}
                className={styles.link}
                href={link.href}
                target="_blank"
              >
                {link.name}
              </Link>
            ))}
          </div>
          <div className={styles.socialsList}>
            {socialLinksArray.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.social}
              >
                <Icon name={link.name} size="large" />
              </a>
            ))}
          </div>
        </div>
      )}
    </aside>
  );
}
