export const MAX_AVAILABILITIES = 10;
export const MIN_AVAILABILITIES = 1;
export const DAYS_COUNT = 7;
export const DEFAULT_START_VALUE = 540;
export const DEFAULT_END_VALUE = 1080;

export interface Availabilities {
  id: string;
  enabled: boolean;
  availabilities: Availability[];
}

export interface Availability {
  enabled: boolean;
  day: number;
  hours: AvailabilityHour[];
}

export interface AvailabilityHour {
  start: number;
  end: number;
}
