import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { CalendarStatus } from "@/features/consultation/domain/models/booking";
import { Consultation } from "@/features/consultation/domain/models/consultation";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import { consultationUseCases } from "@/features/consultation/domain/use-cases/consultation";
import bookingDataSourceImplementation, {
  CalendarProviderResponse,
} from "@/features/consultation/infrastructure/datasources/booking";
import { consultationDataSourceImplementation } from "@/features/consultation/infrastructure/datasources/consultation";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useConsultationsQuery = () => {
  const httpClient = httpImplementation();
  const creatorDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCase = consultationUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["consultations"],
    queryFn: () => useCase.getConsultations<AxiosResponse<Consultation[]>>(),
    select: (data) => {
      return (
        data.data.map((consultation) => ({
          ...consultation,
          price: {
            ...consultation.price,
            value: consultation.price.value / 100,
          },
        })) || []
      );
    },
  });
};

export const useDeleteConsultationMutation = () => {
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCaseConsultationsList = consultationUseCases(
    consultationDataSourceImpl
  );
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => {
      return useCaseConsultationsList.deleteConsultation(id);
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["consultations"],
      });
    },
  });
};

export const useCalendarProvidersQuery = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["calendars-providers"],
    queryFn: () =>
      booking.getCalendarProviders<AxiosResponse<CalendarProviderResponse>>(),
    select: (response) => {
      return (
        response.data.providers.find(
          (provider) => provider.status === CalendarStatus.SYNCED
        ) || false
      );
    },
    notifyOnChangeProps: ["data"],
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
  };
};
