import {
  BOOKINGS_LIST,
  CALENDAR,
  CONSULTATIONS_LIST,
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_BY_ID,
  CONSULTATION_CALENDAR_CALLBACK,
  CONSULTATION_CALENDAR_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
  CONSULTATION_CREATION,
  CONSULTATION_EDIT,
} from "@/constants/routes";
import { CONSULTATION_CONFIRMATION } from "@/constants/routes";
import { CreatorContext } from "@/contexts/creator/Creator";
import { Navigate, Route, Routes } from "react-router-dom";

import { Error } from "@/features/consultation/presentation/components/error/Error";
import { Layout } from "@/features/consultation/presentation/layout/Layout";
import { Calendar } from "@/features/consultation/presentation/pages/Calendar/Calendar";
import { CalendarSetup } from "@/features/consultation/presentation/pages/CalendarSetup";
import { Consultation } from "@/features/consultation/presentation/pages/Consultation";
import { ConsultationsList } from "@/features/consultation/presentation/pages/ConsultationList";
import OAuhCallback from "@/features/consultation/presentation/pages/OAuthCallback";
import { useCalendarProvidersQuery } from "@/features/consultation/presentation/routes/viewModel";

import { AvailabilitySetup } from "../pages/AvailabilitySetup";
import { Bookings } from "../pages/Bookings";
import { Confirmation } from "../pages/Confirmation";
import { Setup } from "../pages/Setup";
import { useCreatorMeQuery } from "../pages/Setup/viewModel";
import { Update } from "../pages/Update";

export const ConsultationRoutes = () => {
  const ConsultationCreation = () => {
    const { data } = useCalendarProvidersQuery();
    return data ? (
      <Setup />
    ) : (
      <Navigate to={`/catalog/${CONSULTATION_CALENDAR_SETUP}`} replace={true} />
    );
  };

  const { data: creator, isError, refetch } = useCreatorMeQuery();

  const renderPage = (component: JSX.Element) => {
    return isError ? <Error onClick={() => refetch()} /> : component;
  };

  const routeConfig = [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: CONSULTATIONS_LIST,
          element: renderPage(<ConsultationsList />),
        },
        { path: CALENDAR, element: renderPage(<Calendar />) },
        {
          path: CONSULTATION_CALENDAR_SETUP,
          element: renderPage(<CalendarSetup />),
        },
        {
          path: CONSULTATION_AVAILABILITY_SETUP,
          element: renderPage(<AvailabilitySetup />),
        },
        {
          path: CONSULTATION_CONFIGURATION_CREATION,
          element: renderPage(<ConsultationCreation />),
        },
        {
          path: CONSULTATION_CREATION,
          element: renderPage(<ConsultationCreation />),
        },
        { path: CONSULTATION_EDIT, element: renderPage(<Update />) },
        { path: CONSULTATION_BY_ID, element: renderPage(<Consultation />) },
        {
          path: CONSULTATION_CONFIRMATION,
          element: renderPage(<Confirmation />),
        },
        { path: BOOKINGS_LIST, element: renderPage(<Bookings />) },
        { path: CONSULTATION_CALENDAR_CALLBACK, element: <OAuhCallback /> },
      ],
    },
  ];

  return (
    <CreatorContext category={creator?.category || ""}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {routeConfig[0].children.map((route, index) => {
            return (
              <Route key={index} path={route.path} element={route.element} />
            );
          })}
        </Route>
      </Routes>
    </CreatorContext>
  );
};
