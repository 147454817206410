import React, { useRef } from "react";

import { type Placement } from "react-aria";
import {
  AriaMenuProps,
  useMenu,
  useMenuItem,
  useMenuTrigger,
} from "react-aria";
import {
  MenuTriggerProps,
  useMenuTriggerState,
  useTreeState,
} from "react-stately";

import { Popover } from "@leeloo/core";

import * as styles from "./menu.css";

interface MenuProps<T> extends AriaMenuProps<T>, MenuTriggerProps {
  label?: string;
  className?: string;
  buttonComponent: React.ReactElement;
  popoverProps?: {
    placement?: Placement;
    withUnderlay?: boolean;
  };
}

export default function Menu<T extends object>(props: MenuProps<T>) {
  const { popoverProps, buttonComponent } = props;

  const state = useMenuTriggerState(props);
  const ref = useRef(null);
  const popoverRef = useRef(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<T>({}, state, ref);

  return (
    <>
      {React.createElement(buttonComponent.type, {
        ...buttonComponent.props,
        buttonRef: ref,
        ...menuTriggerProps,
      })}
      {state.isOpen && (
        <Popover
          state={state}
          offset={10}
          popoverRef={popoverRef}
          triggerRef={ref}
          withUnderlay={popoverProps?.withUnderlay}
          placement={popoverProps?.placement}
        >
          <MenuList {...props} {...menuProps} />
        </Popover>
      )}
    </>
  );
}

function MenuList<T extends object>(props: AriaMenuProps<T>) {
  const state = useTreeState(props);
  const ref = useRef(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <ul {...menuProps} ref={ref} className={styles.menuList}>
      {[...state.collection].map((item) => (
        <MenuItem key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
}

export function MenuItem({
  item,
  state,
}: {
  item: {
    rendered: any;
    key: any;
  };
  state: ReturnType<typeof useTreeState>;
}) {
  const ref = useRef(null);
  const { menuItemProps, isSelected } = useMenuItem(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      {...menuItemProps}
      ref={ref}
      className={styles.menuItem}
      data-testid={item?.key}
    >
      {item.rendered}
      {isSelected && <span aria-hidden="true">✅</span>}
    </li>
  );
}
