import { useTranslation } from "react-i18next";

import { ButtonIcon, Title } from "@leeloo/core";

import {
  setNextMonth,
  setPreviousMonth,
} from "@/features/consultation/presentation/pages/Bookings/viewModel";

import * as styles from "./bookings-calendar-head.css";

export interface BookingsCalendarHeadProps {
  selectedMonth: Date;
  setSelectedMonth: (selectedDate: Date) => void;
  disabledLeft: boolean;
  disabledRight: boolean;
}

export function BookingsCalendarHead({
  selectedMonth,
  setSelectedMonth,
  disabledLeft,
  disabledRight,
  ...props
}: BookingsCalendarHeadProps) {
  const { i18n } = useTranslation();

  return (
    <div
      className={styles.calendarHead}
      data-testid="bookings-calendar-head"
      {...props}
    >
      <ButtonIcon
        iconSize="large"
        variant="simple"
        data-testid="bookings-calendar-head-icon-left"
        iconName="chevron-left"
        size="large"
        disabled={disabledLeft}
        onPress={() => setPreviousMonth(selectedMonth, setSelectedMonth)}
      />
      <Title
        as="h4"
        className={styles.month}
        data-testid="bookings-calendar-head-title"
      >
        {new Date(selectedMonth).toLocaleString(i18n.language, {
          month: "long",
          year: "numeric",
        })}
      </Title>
      <ButtonIcon
        iconSize="large"
        variant="simple"
        data-testid="bookings-calendar-head-icon-right"
        iconName="chevron-right"
        size="large"
        disabled={disabledRight}
        onPress={() => setNextMonth(selectedMonth, setSelectedMonth)}
      />
    </div>
  );
}
