import { CONSULTATION_CALENDAR_CALLBACK } from "@/constants/routes";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { showToast } from "@leeloo/core";

import { CalendarId } from "@/features/consultation/domain/models/booking";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import bookingDataSourceImplementation from "@/features/consultation/infrastructure/datasources/booking";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useSynchronizeCalendarQuery = () => {
  const bookingDataSource = bookingDataSourceImplementation(
    httpImplementation()
  );
  const booking = bookingUseCases(bookingDataSource);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return useQuery({
    queryKey: ["sync-calendar"],
    queryFn: async () => {
      try {
        const response = await booking.synchroniseCalendar({
          code: searchParams.get("code") || "",
          provider: localStorage.getItem("provider") as CalendarId,
          redirect_uri: `${window.location.origin}/app/catalog/${CONSULTATION_CALENDAR_CALLBACK}`,
        });

        showToast({
          title: t("creator_calendar__sync__toast__title", {
            provider: localStorage.getItem("provider") as CalendarId,
          }),
          variant: "success",
        });

        return response;
      } catch (e: any) {
        showToast({
          description: t("technical_error_toast"),
          title: t("technical_error_toast_title"),
          variant: "error",
        });

        throw e;
      } finally {
        navigate(`${localStorage.getItem("redirect_uri")}`, {
          replace: true,
        });
        // localStorage.removeItem("redirect_uri");
        // localStorage.removeItem("provider");
      }
    },
  });
};
