import { useQuery } from "@tanstack/react-query";

import creatorUseCases from "@/features/post/domain/use-cases/creator";
import { creatorDataSourceImplementation } from "@/features/post/infrastructure/datasources/creator/creatorDataSourceImplementation";
import {
  PostCreationStatus,
  StoreMedia,
  store,
} from "@/features/post/infrastructure/datastores/store/store";
import { storeImplementation } from "@/features/post/infrastructure/datastores/store/storeImplementation";
import httpImplementation from "@/features/post/infrastructure/services/httpImplementation";

export const useStore = () => {
  const storeImpl = store(storeImplementation());

  return {
    media: storeImpl.media,
    caption: storeImpl.caption,
    isPrivate: storeImpl.isPrivate,
    isScheduled: storeImpl.isScheduled,
    status: storeImpl.status,
    addMedia: (file: StoreMedia) => storeImpl.addMedia(file),
    deleteMedia: () => storeImpl.deleteMedia(),
    updateCaption: (caption: string) => storeImpl.updateCaption(caption),
    updateStatus: (status: PostCreationStatus) =>
      storeImpl.updateStatus(status),
    resetStore: () => storeImpl.resetStore(),
  };
};

export const useCreatorMeQuery = () => {
  const httpClient = httpImplementation;
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient());
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-me"],
    queryFn: () => useCase.getMe(),
    select: (data) => data,
    notifyOnChangeProps: ["data"],
  });
};

export const useCreatorProfilePictureQuery = () => {
  const httpClient = httpImplementation;
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient());
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-profile-picture"],
    queryFn: () => useCase.getProfilePicture(),
    select: (data) => data,
    notifyOnChangeProps: ["data"],
  });
};
