import { useContext } from "react";

import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Text, Title } from "@leeloo/core";

import * as styles from "./sidebar-consultation-list.css";

export default function SidebarConsultationList() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;
  return (
    <Heading>
      <Illustration name="live-alt" data-testid="illustration" />
      <Title centered={true} as="h3" data-testid="consultation-layout-title">
        {isAstro
          ? t("creator_consultations__sidebar__title_desktop")
          : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
      </Title>
      <Text className={styles.text} data-testid="sidebar-text">
        {isAstro
          ? t("creator_consultations__sidebar__descriptiontwo_desktop")
          : t(
              "NSFW_Live_1_1_creator_video_call_sidebar_descriptiontwo_desktop"
            )}
      </Text>
    </Heading>
  );
}
