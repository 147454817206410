import React from "react";

import clsx from "clsx";

import { Button } from "@leeloo/core";

import * as styles from "./form-footer.css";

interface FormFooterProps {
  id?: string;
  submitLabel: string;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const FormFooter = ({
  id,
  isLoading,
  disabled,
  submitLabel,
  onClick,
  ...props
}: FormFooterProps) => {
  const type = onClick ? "button" : "submit";
  return (
    <div
      className={clsx(styles.footer, props.className)}
      data-testid="consultation-form-footer"
      {...props}
    >
      <Button
        data-testid="consultation-form-footer-button"
        isLoading={isLoading}
        disabled={disabled}
        className={styles.cta({ mobile: true })}
        onPress={onClick && onClick}
        role={type}
        size="medium"
        type={type}
      >
        {submitLabel}
      </Button>
      <Button
        data-testid={
          id
            ? `consultation-form-footer-button-${id}`
            : "consultation-form-footer-button"
        }
        isLoading={isLoading}
        disabled={disabled}
        className={styles.cta({ desktop: true })}
        onPress={onClick && onClick}
        role={type}
        size="large"
        type={type}
      >
        {submitLabel}
      </Button>
    </div>
  );
};

export default FormFooter;
