import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import { Consultation } from "@/features/consultation/domain/models/consultation";
import { consultationUseCases } from "@/features/consultation/domain/use-cases/consultation";
import { consultationDataSourceImplementation } from "@/features/consultation/infrastructure/datasources/consultation";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useConsultationByIdQuery = () => {
  const urlParams = useParams();
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCase = consultationUseCases(consultationDataSourceImpl);

  return useQuery({
    queryKey: ["consultations", urlParams.id],
    queryFn: () => {
      return useCase.getConsultationById<AxiosResponse<Consultation>>(
        urlParams.id as string
      );
    },
    select: (data) => {
      const consultation = {
        ...data.data,
        price: {
          currency: data.data.price.currency,
          value: data.data.price.value / 100,
        },
      };
      return consultation;
    },
  });
};

export const useDeleteConsultationMutation = () => {
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCaseConsultationsList = consultationUseCases(
    consultationDataSourceImpl
  );
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => {
      return useCaseConsultationsList.deleteConsultation(id);
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["consultations"],
      });
    },
  });
};
