import { create } from "zustand";

import type {
  DisplayedCartridges,
  Media,
  Price,
  PushDate,
  PushSlice,
} from "./pushStore";

const state = {
  medias: [],
  description: "",
  audience: [],
  publicationDate: {
    year: null,
    month: null,
    day: null,
    hour: null,
    minute: null,
  },
  hasDiscount: false,
  price: {
    amount: null,
    discountAmount: null,
    discountDuration: null,
  },
  isPushConfigured: false,
  isPushConfirmed: false,
  isVisibilityConfigured: false,
  isScheduled: false,
  isPriceConfigured: false,
  displayedCartridges: {
    priceInformation: true,
    pricePromotion: true,
  },
};

export const pushStoreImplementation = create<PushSlice>()((set, getState) => ({
  ...state,
  publishNow: () => {
    return (
      !getState().publicationDate.year &&
      !getState().publicationDate.month &&
      !getState().publicationDate.day &&
      !getState().publicationDate.hour &&
      !getState().publicationDate.minute
    );
  },
  updateDescription: (description) =>
    set((state) => ({ ...state, description })),
  addMedia: (media: Media) =>
    set((state) => ({
      ...state,
      medias: [...state.medias, media],
    })),
  deleteMedia: (media) => {
    set((state) => ({
      ...state,
      medias: state.medias.filter((m) => m.name !== media),
    }));
  },
  addAudience: (target) =>
    set((state) => ({
      ...state,
      audience: [...state.audience, target],
    })),
  deleteAudience: (target) =>
    set((state) => ({
      ...state,
      audience: state.audience.filter((p) => p !== target),
    })),
  updatePrice: (price: Partial<Price>) =>
    set((state) => ({
      ...state,
      price: {
        ...state.price,
        ...price,
      },
    })),
  updateIsPushConfigured: (isPushConfigured: boolean) =>
    set((state) => ({ ...state, isPushConfigured })),
  updateIsPushConfirmed: (isPushConfirmed: boolean) =>
    set((state) => ({ ...state, isPushConfirmed })),
  updateIsVisibilityConfigured: (isVisibilityConfigured: boolean) =>
    set((state) => ({ ...state, isVisibilityConfigured })),
  updateIsScheduled: (isScheduled: boolean) =>
    set((state) => ({ ...state, isScheduled })),
  updatePublicationDate: (publicationDate: PushDate) =>
    set((state) => ({ ...state, publicationDate })),
  updateIsPriceConfigured: (isPriceConfigured: boolean) =>
    set((state) => ({ ...state, isPriceConfigured })),
  resetPushStore: () => {
    set(state);
  },
  updateDisplayedCartridges: (
    cartridgesStatus: Partial<DisplayedCartridges>
  ) => {
    set((state) => ({
      ...state,
      displayedCartridges: {
        ...state.displayedCartridges,
        ...cartridgesStatus,
      },
    }));
  },
  updateHasDiscount: (hasDiscount: boolean) => {
    set((state) => ({ ...state, hasDiscount }));
  },
}));
