import { create } from "zustand";

import {
  type ConsultationSlice,
  type ConsultationState,
  ConsultationStep,
} from "./store";

const defaultState: ConsultationState = {
  id: "",
  theme: undefined,
  title: "",
  caption: "",
  duration: null,
  price: null,
  status: ConsultationStep.Configuration,
};

export const storeImplementation = create<ConsultationSlice>()((set) => ({
  ...defaultState,
  updateConsultation: (values: Partial<ConsultationState>) => {
    set((state) => ({
      ...state,
      ...values,
    }));
  },
  resetStore: () => {
    set(defaultState);
  },
}));
