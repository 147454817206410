import { Availabilities } from "@/features/consultation/domain/models/availability";
import { AvailabilityRepository } from "@/features/consultation/domain/repositories/availability";
import { HttpGateway } from "@/features/consultation/infrastructure/services/http";

export type AvailabilityDTO = Availabilities;

export const availabilitiesDataSourceImplementation = (
  gateway: HttpGateway
): AvailabilityRepository => ({
  getAvailabilities: async <T>(timezone: string) => {
    return gateway.get<T>(
      `/bookings/availabilities/range?timezone=${timezone}`
    );
  },
  updateAvailabilities: async <T>(params: AvailabilityDTO) => {
    return gateway.patch<T>("/bookings/availabilities/range", params);
  },
  createAvailabilities: async <T>(params: AvailabilityDTO) => {
    return gateway.post<T>("/bookings/availabilities/range", params);
  },
});

export default availabilitiesDataSourceImplementation;
