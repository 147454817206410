import { useContext } from "react";

import { Context } from "@/contexts/creator/Creator";
import * as yup from "yup";

import {
  CONSULTATION_DURATIONS,
  MAX_CAPTION_CHARACTERS,
  MAX_PRICE_VALUE,
  MAX_TITLE_CHARACTERS,
  MIN_PRICE_VALUE,
} from "@/features/consultation/domain/constants";
import { ConsultationTheme } from "@/features/consultation/domain/models/consultation";

export const FORM_MIN_PRICE_VALUE = MIN_PRICE_VALUE / 100;
export const FORM_MAX_PRICE_VALUE = MAX_PRICE_VALUE / 100;

export const consultationSetup = yup.object({
  title: yup
    .string()
    .trim()
    .max(
      MAX_TITLE_CHARACTERS,
      "creator_calendar__steptwo__service__title_maximum_error"
    )
    .required(),
  caption: yup
    .string()
    .trim()
    .max(
      MAX_CAPTION_CHARACTERS,
      "creator_calendar__steptwo__service__description_maximum_error"
    )
    .required(),
  duration: yup
    .number()
    .nullable()
    .test(
      "durationValue",
      `Duration should be between ${CONSULTATION_DURATIONS[0] / 60} and  ${
        CONSULTATION_DURATIONS[CONSULTATION_DURATIONS.length - 1] / 60
      } minutes`,
      (value) => {
        return CONSULTATION_DURATIONS.some((duration) => {
          return value === duration || !value;
        });
      }
    )
    .required(),
  price: yup
    .number()
    .nullable()
    .min(
      FORM_MIN_PRICE_VALUE,
      "creator_calendar__steptwo__service__price_minimum_error"
    )
    .max(
      FORM_MAX_PRICE_VALUE,
      "creator_calendar__steptwo__service__price_maximum_error"
    )
    .required(),
});

export const consultationSetupAstro = consultationSetup.concat(
  yup.object({
    theme: yup
      .mixed()
      .oneOf(
        [Object.values(ConsultationTheme), null],
        "Theme does not have correct value"
      )
      .required(),
  })
);
