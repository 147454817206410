import { useCallback, useContext, useEffect } from "react";

import {
  CALENDAR,
  CONSULTATIONS_LIST,
  CONSULTATION_CALENDAR_SETUP,
  CONSULTATION_CREATION,
  CREATOR_PROFILE,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ButtonIcon,
  Cartridge,
  IllustrationName,
  Loader,
  Title,
  showToast,
} from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import { MAX_PRESTATIONS } from "@/features/consultation/domain/constants";
import {
  Consultation,
  ConsultationTheme,
} from "@/features/consultation/domain/models/consultation";
import { trackingImplementation } from "@/features/consultation/infrastructure/services/trackingImplementation";
import Card from "@/features/consultation/presentation/components/card";
import EmptyList from "@/features/consultation/presentation/components/empty-list/EmptyList";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import ModalConsultation from "@/features/consultation/presentation/components/modal-consultation/ModalConsultation";
import { illustrationMap } from "@/features/consultation/presentation/utils/constants/illustrations";

import { useModalWithData } from "@/hooks/useModalWithData";

import * as styles from "./consultations-list.css";
import {
  useCalendarProvidersQuery,
  useConsultationsQuery,
  useDeleteConsultationMutation,
} from "./viewModel";

export function ConsultationsList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trackImpl = trackingImplementation;
  const { isAstro } = useContext(Context).creator;

  const {
    data: consultations,
    isLoading: isLoadingConsultations,
    isError,
    refetch,
    isRefetching: isRefetchingConsultations,
  } = useConsultationsQuery();
  const { mutateAsync: deleteConsultationMutation } =
    useDeleteConsultationMutation();
  const {
    data: isCalendarSynced,
    isLoading: isLoadingCalendarsProviders,
    isError: isCalendarsProvidersError,
  } = useCalendarProvidersQuery();

  const illustrationAstro = (theme: ConsultationTheme) => {
    return (
      (illustrationMap[theme] as IllustrationName) ||
      ("cartridge-informative" as IllustrationName)
    );
  };

  const onCreateConsultation = () => {
    trackImpl.track("creator_track_add_new_booking", "");
    if (consultations && consultations.length < MAX_PRESTATIONS) {
      if (isCalendarSynced) navigate(`/catalog/${CONSULTATION_CREATION}`);
      else {
        navigate(`/catalog/${CONSULTATION_CALENDAR_SETUP}`);
      }
    } else
      showToast({
        title: t("creator_service_maximum_reached"),
        variant: "error",
      });
  };

  const onDelete = useCallback(async (id: string) => {
    try {
      await deleteConsultationMutation(id);
      showToast({
        title: isAstro
          ? t("creator_service_consultation_deleted")
          : t("NSFW_Live_1_1_creator_delete_success_message"),
        variant: "success",
      });
    } catch {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    } finally {
      hideDeleteModal();
    }
  }, []);

  const [showDeleteModal, hideDeleteModal] = useModalWithData(
    (id: string) => () => {
      return (
        <ModalConsultation
          illustration="warning"
          title={t("creator_createservice__closemodal__title")}
          text={
            isAstro
              ? t("creator_service__delete__content__description")
              : t("NSFW_Live_1_1_creator_service__delete__content__description")
          }
          hideModal={() => hideDeleteModal()}
          mainButtonParams={{
            text: t("creator_service__delete__actions__button"),
            variant: "destructive",
            onAction: () => onDelete(id),
          }}
        />
      );
    }
  );

  useEffect(() => {
    trackImpl.page_view("creator_page_summary_consultation");
  }, []);

  if (
    isLoadingConsultations ||
    isRefetchingConsultations ||
    isLoadingCalendarsProviders
  ) {
    return (
      <Loader className={styles.loader} data-testid="cl-loader" spin={true} />
    );
  }

  return (
    <>
      <ContentHeader
        title={
          isAstro
            ? t("creator_service_empty__header__title")
            : t("creatorstools_services_catalogue")
        }
        data-testid="cl-header-desktop"
      />
      <MobileContentHeader
        withCloseIcon
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
        title={
          isAstro
            ? t("creator_service_empty__header__title")
            : t("NSFW_Live_1_1_creator_service_empty__header__title")
        }
        data-testid="cl-header-mobile"
      />
      {isError || isCalendarsProvidersError ? (
        <Error
          data-testid="consultations-list-error"
          onClick={() => refetch()}
        />
      ) : (
        <div className={styles.consultations} data-testid="consultations-list">
          {isAstro && (
            <Title as="h3" className={styles.title} data-testid="cl-title">
              {t("creator_service_empty__heading__title")}
            </Title>
          )}
          {!isCalendarSynced && (
            <Cartridge
              type="danger"
              className={styles.calendarCartridge}
              title={t("creator_service__nocalendar__cartouche_title")}
              description={t(
                "creator_service__nocalendar__cartouche_description"
              )}
              buttons={[
                <Button
                  key="calendar"
                  theme="inverted"
                  variant="tertiary"
                  size="small"
                  onPress={() =>
                    navigate(`/catalog/${CALENDAR}`, {
                      state: { locationPage: "list" },
                    })
                  }
                >
                  {t("creator_service__nocalendar__cartouche_button")}
                </Button>,
              ]}
            />
          )}
          {consultations && consultations.length > 0 ? (
            <div className={styles.consultationsList} data-testid="cl-list">
              <Button
                leftIcon="add"
                variant="secondary"
                className={styles.actionButton}
                data-testid="cl-add-button"
                onPress={() => onCreateConsultation()}
              >
                {isAstro
                  ? t("creator_service_empty__actions__button")
                  : t("NSFW_LIVE_1_1_create_new_video_call_button")}
              </Button>
              <div className={styles.list}>
                {consultations.map((consultation: Consultation) => (
                  <Card
                    className={styles.card}
                    key={consultation.id}
                    consultation={consultation}
                    withClamp
                    illustrationName={
                      isAstro
                        ? illustrationAstro(
                            consultation.theme as ConsultationTheme
                          )
                        : "live-not-astro"
                    }
                    children={[
                      <ButtonIcon
                        iconName="visibility-on"
                        size="medium"
                        variant="secondary"
                        key="card-view-button"
                        data-testid="card-view-button"
                        children="Réservations"
                        onPress={() =>
                          navigate(
                            `/catalog/${CONSULTATIONS_LIST}/${consultation.id}`
                          )
                        }
                      />,
                      <ButtonIcon
                        iconName="delete"
                        size="medium"
                        key="card-delete-button"
                        variant="destructive"
                        data-testid="card-delete-button"
                        onPress={() => showDeleteModal(consultation.id)}
                        children="Supprimer"
                      />,
                      <Button
                        size="medium"
                        leftIcon="edit-content"
                        key="card-edit-button"
                        children={t(
                          "profile_configuration_picture_preview_modal_modify_button"
                        )}
                        className={styles.editButton}
                        data-testid="card-edit-button"
                        onPress={() => {
                          navigate(`edit/${consultation?.id}`);
                        }}
                      />,
                    ]}
                  />
                ))}
              </div>
            </div>
          ) : (
            <EmptyList
              illustration="empty-state"
              title={
                isAstro
                  ? "creator_service_empty__content__title"
                  : t("NSFW_Live_1_1_creator_service_empty__content__title")
              }
              text={
                isAstro
                  ? t("creator_service_empty__content__description")
                  : t(
                      "NSFW_Live_1_1_creator_service_empty__content__description"
                    )
              }
              children={
                <Button
                  leftIcon="add"
                  className={styles.actionButton}
                  data-testid="cl-empty-add-button"
                  onPress={() => onCreateConsultation()}
                >
                  {isAstro
                    ? t("creator_service_empty__actions__button")
                    : t("NSFW_LIVE_1_1_create_new_video_call_button")}
                </Button>
              }
            />
          )}
        </div>
      )}
    </>
  );
}
