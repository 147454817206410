import { ButtonIcon, SelectLegacy as Select, Text } from "@leeloo/core";

import { AvailabilityHour } from "@/features/consultation/domain/models/availability";
import { formatMinutesToStringHour } from "@/features/consultation/presentation/utils/date";

import { FieldProps } from "../form-availability";
import * as styles from "./range.css";

interface RangeProps {
  hour: AvailabilityHour;
  id?: string;
  items: { label: string; value: string | number }[];
  updateField: (selectedHour: number, field: FieldProps) => void;
  removeHourFromDay: () => void;
}

export const Range = ({
  hour,
  id,
  items,
  removeHourFromDay,
  updateField,
  ...props
}: RangeProps) => {
  return (
    <div className={styles.selectFields} key={hour.end} {...props}>
      <Select
        data-testid="range-start-select"
        items={items}
        selectedItem={{
          label: formatMinutesToStringHour(hour.start),
          value: hour.start,
        }}
        onSelectedItemChange={(changes) => {
          const selectedItemValue = changes?.selectedItem?.value;
          updateField(selectedItemValue as number, "start");
        }}
      />
      <Text data-testid="range-separator">-</Text>
      <Select
        data-testid="range-end-select"
        items={items}
        selectedItem={{
          value: hour.end,
          label: formatMinutesToStringHour(hour.end),
        }}
        onSelectedItemChange={(changes) => {
          const selectedItemValue = changes?.selectedItem?.value;
          updateField(selectedItemValue as number, "end");
        }}
      />
      <ButtonIcon
        data-testid={`range-button-delete-${id}`}
        iconName="delete"
        variant="simple"
        size="small"
        iconSize="medium"
        onPress={removeHourFromDay}
      />
    </div>
  );
};
