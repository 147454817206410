import formatCurrency from "@/utils/convertPriceCurrency";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import {
  Icon,
  Illustration,
  IllustrationName,
  Text,
  Title,
} from "@leeloo/core";

import { Consultation } from "@/features/consultation/domain/models/consultation";

import * as styles from "./card.css";

export type CardProps = {
  className?: string;
  consultation: Consultation;
  illustrationName: IllustrationName;
  withClamp?: boolean;
  children?: React.ReactNode[];
};

export const Card = ({
  className,
  consultation,
  illustrationName,
  children,
  withClamp,
  ...rest
}: CardProps) => {
  const { i18n, t } = useTranslation();

  const formattedPrice = formatCurrency(
    consultation.price.value,
    consultation.price.currency,
    i18n.language
  );

  return (
    <div className={clsx(styles.card, className)} data-testid="card" {...rest}>
      <Illustration name={illustrationName} className={styles.illustration} />
      <div className={styles.cardContent}>
        <Title as="h3" className={styles.header}>
          <span data-testid="card-title" className={styles.title}>
            {consultation.title}
          </span>
          <span data-testid="card-price">{formattedPrice}</span>
        </Title>
        <div className={styles.duration} data-testid="card-duration">
          <Text>{t("creator_calendar__stepthree___summary__duration")} </Text>
          <Text as="b">
            {consultation.duration / 60}
            {t("time_minutes_short")}
          </Text>
          <div className={styles.icons}>
            <Icon name="live" size="large" />
            <Icon name="phone" size="large" />
          </div>
        </div>
        <Text
          className={styles.caption({ withClamp: withClamp })}
          data-testid="card-caption"
        >
          {consultation.caption}
        </Text>
        <div className={styles.actions}>{children}</div>
      </div>
    </div>
  );
};

export default Card;
