import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { showToast } from "@leeloo/core";

import { CalendarId } from "@/features/consultation/domain/models/booking";
import { Consultation } from "@/features/consultation/domain/models/consultation";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import consultationUseCases from "@/features/consultation/domain/use-cases/consultation";
import bookingDataSourceImplementation, {
  CalendarProviderResponse,
} from "@/features/consultation/infrastructure/datasources/booking";
import { consultationDataSourceImplementation } from "@/features/consultation/infrastructure/datasources/consultation";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useConsultationByIdQuery = () => {
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCase = consultationUseCases(consultationDataSourceImpl);
  const location = useLocation();

  const consultationId = location.state?.consultationId;

  return useQuery({
    queryKey: ["consultations", consultationId],
    queryFn: () =>
      useCase.getConsultationById<AxiosResponse<Consultation>>(consultationId),
    select: (data) => {
      const consultation = data.data;
      return {
        ...consultation,
        price: {
          ...consultation.price,
          value: consultation.price.value / 100,
        },
      };
    },
    notifyOnChangeProps: ["data"],
  });
};

export const useDeleteConsultationMutation = () => {
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCasesConfirmation = consultationUseCases(consultationDataSourceImpl);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["consultations"],
    mutationFn: (id: string) => {
      return useCasesConfirmation.deleteConsultation(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["consultations"],
      });
    },
  });
};

export const useConsultationsQuery = () => {
  const httpClient = httpImplementation();
  const creatorDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCase = consultationUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["consultations"],
    queryFn: () => useCase.getConsultations<AxiosResponse<Consultation[]>>(),
    select: (data) => {
      return (
        data.data.map((consultation) => ({
          ...consultation,
          price: {
            ...consultation.price,
            value: consultation.price.value / 100,
          },
        })) || []
      );
    },
  });
};

export const useCalendarProvidersQuery = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ["calendars-providers"],
    queryFn: () =>
      booking.getCalendarProviders<AxiosResponse<CalendarProviderResponse>>(),
    select: (data) => data?.data,
    notifyOnChangeProps: ["data"],
  });

  return {
    data,
    isLoading,
    isSuccess,
  };
};

export const useUnsyncCalendarMutation = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isError, isSuccess } = useMutation({
    mutationFn: (provider: CalendarId) =>
      booking.desynchroniseCalendar(provider),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["calendars-providers"],
      });
      showToast({
        title: t("creator_calendar__unsync__toast__title"),
        variant: "success",
      });
    },
    onError: () => {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    },
  });

  return {
    unSynchroniseCalendar: (provider: CalendarId) => mutate(provider),
    isError,
    isSuccess,
  };
};
