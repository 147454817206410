import { create } from "zustand";

import { PostCreationStatus, PostSlice } from "./store";

const defaultState = {
  media: null,
  caption: "",
  isPostConfirmed: false,
  isPrivate: true,
  isScheduled: false,
  status: PostCreationStatus.SETUP,
  publicationDate: new Date(),
};

export const storeImplementation = create<PostSlice>()((set) => ({
  ...defaultState,
  updateCaption: (caption) => set((state) => ({ ...state, caption })),
  addMedia: (media) => set(() => ({ media: media })),
  deleteMedia: () => {
    set((state) => ({ ...state, media: null }));
  },
  resetStore: () => {
    set(defaultState);
  },
  updateIsPostConfirmed: (isPostConfirmed: boolean) =>
    set((state) => ({ ...state, isPostConfirmed })),
  updateIsPrivate: (isPrivate: boolean) => {
    set((state) => ({ ...state, isPrivate }));
  },
  updateIsScheduled: (isScheduled: boolean) =>
    set((state) => ({ ...state, isScheduled })),
  updatePublicationDate: (publicationDate: Date) =>
    set((state) => ({ ...state, publicationDate })),
  updateStatus: (status: PostCreationStatus) => {
    set((state) => ({
      ...state,
      status: status,
    }));
  },
}));
