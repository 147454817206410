import * as React from "react";

import {
  BOOKINGS_LIST,
  CALENDAR,
  CONSULTATIONS_LIST,
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_CALENDAR_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
  CONSULTATION_CONFIRMATION,
  CONSULTATION_CREATION,
} from "@/constants/routes";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { Layout as CommonLayout } from "@/components/layout/Layout";
import { Content } from "@/components/layout/Layout";
import NavigationBar from "@/components/navigation-bar/NavigationBar";
import { SidebarDesktop } from "@/components/sidebar-desktop/SidebarDesktop";

import SidebarAvailability from "@/features/consultation/presentation/pages/AvailabilitySetup/containers/sidebar-availability";
import SidebarBookings from "@/features/consultation/presentation/pages/Bookings/containers/sidebar-bookings";
import SidebarCalendar from "@/features/consultation/presentation/pages/Calendar/containers/sidebar-calendar";
import SidebarCalendarSetup from "@/features/consultation/presentation/pages/CalendarSetup/containers/sidebar-calendar-setup";
import SidebarConfirmation from "@/features/consultation/presentation/pages/Confirmation/containers/sidebar-confirmation";
import SidebarConsultation from "@/features/consultation/presentation/pages/Consultation/containers/sidebar-consultation";
import SidebarConsultationList from "@/features/consultation/presentation/pages/ConsultationList/containers/sidebar-consultation-list";
import SidebarConsultationSetup from "@/features/consultation/presentation/pages/Setup/containers/sidebar-consultation-setup";
import SidebarConsultationUpdate from "@/features/consultation/presentation/pages/Update/containers/sidebar-consultation-update";

import * as styles from "./layout.css";

export const Layout = () => {
  const location = useLocation();
  const urlParams = useParams();

  const renderSidebarContent = () => {
    switch (location.pathname) {
      case `/catalog/${CONSULTATION_CONFIGURATION_CREATION}`:
        return <SidebarConsultationSetup />;
      case `/catalog/${CONSULTATION_CONFIRMATION}`:
        return <SidebarConfirmation />;
      case `/catalog/${CONSULTATIONS_LIST}`:
        return <SidebarConsultationList />;
      case `/catalog/consultations/edit/${urlParams.id}`:
        return <SidebarConsultationUpdate />;
      case `/catalog/${CONSULTATION_CALENDAR_SETUP}`:
        return <SidebarCalendarSetup />;
      case `/catalog/${CONSULTATION_AVAILABILITY_SETUP}`:
        return <SidebarAvailability />;
      case `/catalog/${CALENDAR}`:
        return <SidebarCalendar />;
      case `/catalog/${CONSULTATION_CREATION}`:
        return <SidebarConsultationSetup />;
      case `/catalog/${BOOKINGS_LIST}`:
        return <SidebarBookings />;
      default:
        return <SidebarConsultation />;
    }
  };

  return (
    <CommonLayout
      className={styles.layout}
      navbar={<NavigationBar withProfileMenu={false} />}
    >
      <SidebarDesktop withFooter>{renderSidebarContent()}</SidebarDesktop>
      <Content data-testid="consultation-layout-content">
        <Outlet />
      </Content>
    </CommonLayout>
  );
};
