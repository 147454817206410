import { useContext } from "react";

import {
  CALENDAR,
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  Heading,
  Illustration,
  Progression,
  ProgressionStep,
  Title,
} from "@leeloo/core";

export default function SidebarConsultationSetup() {
  const { isAstro } = useContext(Context).creator;
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Heading data-testid="sidebar-consultation-setup">
        <Illustration
          name="live-alt"
          data-testid="sidebar-consultation-setup-illustration"
        />
        <Title
          centered={true}
          as="h3"
          data-testid="sidebar-consultation-setup-title"
        >
          {isAstro
            ? t("creator_consultations__sidebar__title_desktop")
            : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
        </Title>
      </Heading>
      <Progression
        data-testid="sidebar-consultation-setup-progression"
        header={{
          icon: "push",
          title: isAstro
            ? t("creator_consultations__sidebar__titletwo_desktop")
            : t(
                "NSFW_Live_1_1_creator_consultations__sidebar__titletwo_desktop"
              ),
        }}
        steps={
          [
            ...(location.pathname ===
            `/catalog/${CONSULTATION_CONFIGURATION_CREATION}`
              ? [
                  {
                    label: t("creator_consultations__sidebar__text_desktop"),
                    status: "success",
                    link: CALENDAR,
                  },
                ]
              : []),
            {
              label: isAstro
                ? t("creator_consultations__sidebar__texttwo_desktop")
                : t(
                    "NSFW_Live_1_1_creator_consultations__sidebar__texttwo_desktop"
                  ),
              status: "in_progress",
              link: CONSULTATION_CONFIGURATION_CREATION,
            },
          ] as ProgressionStep[]
        }
      />
    </>
  );
}
