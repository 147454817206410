import { useEffect, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Field,
  Input,
  SelectLegacy as Select,
  Text,
  Textarea,
  Title,
} from "@leeloo/core";

import {
  CONSULTATION_DURATIONS,
  MAX_CAPTION_CHARACTERS,
  MAX_TITLE_CHARACTERS,
} from "@/features/consultation/domain/constants";
import { ConsultationTheme } from "@/features/consultation/domain/models/consultation";
import { FormSetupData } from "@/features/consultation/presentation/pages/Setup/Setup";

import { useScrollToError } from "@/hooks/useScrollToError";

import PriceInput from "../price-input/PriceInput";
import * as styles from "./form-setup.css";

export interface FormSetupProps {
  currency: string;
  children?: React.ReactNode;
  isAstro?: boolean;
  onBlur?: (values: Partial<FormSetupData>) => void;
  onSubmit: (data: FormSetupData) => void;
}

export interface SelectData {
  label: string;
  value: string;
}

export const FormSetup = ({
  children,
  isAstro,
  onBlur,
  onSubmit,
  currency,
}: FormSetupProps) => {
  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useFormContext<FormSetupData>();

  const { t } = useTranslation();
  useScrollToError(errors);

  const { theme, duration } = getValues();

  const formattedThemes = Object.values(ConsultationTheme)?.map((theme) => {
    return { label: t(theme as string), value: theme as string };
  });

  const displayDuration = (duration: number) => {
    const hours = Math.trunc(duration / 3600);
    const minutes = (duration / 3600 - hours) * 60;

    if (hours === 1)
      return minutes === 0
        ? `${hours} ${t(
            "creator_calendar__steptwo___service__form__dropdown_hour"
          )}`
        : `${hours} ${t(
            "creator_calendar__steptwo___service__form__dropdown_hour"
          )} ${(duration / 3600 - hours) * 60} ${t(
            "creator_calendar__steptwo___service__form__dropdown_minutes"
          )}`;
    else if (hours > 1)
      return minutes === 0
        ? `${hours} ${t(
            "creator_calendar__steptwo___service__form__dropdown_hours"
          )}`
        : `${hours} ${t(
            "creator_calendar__steptwo___service__form__dropdown_hours"
          )} ${(duration / 3600 - hours) * 60} ${t(
            "creator_calendar__steptwo___service__form__dropdown_minutes"
          )}`;
    else
      return `${duration / 60} ${t(
        "creator_calendar__steptwo___service__form__dropdown_minutes"
      )}`;
  };

  const formattedDurations = CONSULTATION_DURATIONS?.map((duration) => {
    return {
      label: t(displayDuration(duration as number)),
      value: `${duration as number}`,
    };
  });

  // With these states and useEffects we wait for theme and duration data to be fetched before displaying the Select component
  const [defaultTheme, setDefaultTheme] = useState<SelectData | undefined>(
    formattedThemes?.find((formattedItem) => formattedItem.value === theme)
  );

  const [defaultDuration, setDefaultDuration] = useState<
    SelectData | undefined
  >(
    formattedDurations?.find(
      (formattedItem) => formattedItem.value === `${duration}`
    )
  );

  useEffect(() => {
    setDefaultTheme(
      formattedThemes?.find((formattedItem) => formattedItem.value === theme)
    );
  }, [theme]);

  useEffect(() => {
    setDefaultDuration(
      formattedDurations?.find(
        (formattedItem) => formattedItem.value === `${duration}`
      )
    );
  }, [duration]);

  return (
    <div className={styles.element}>
      <div
        data-testid="consultation-form-setup-mobile-header"
        className={styles.mobileHeader}
      >
        <Title
          as="h3"
          children={
            isAstro
              ? t("creator_calendar__steptwo___service__header__title")
              : t(
                  "NSFW_Live_1_1_creator_calendar__steptwo___service__heading__title"
                )
          }
        />
      </div>
      <Text
        className={styles.text}
        children={t(
          "creator_calendar__steptwo___service__heading__description"
        )}
        data-testid="consultation-form-description"
      />
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        {(!theme || defaultTheme) && isAstro && (
          <Controller
            control={control}
            name="theme"
            render={({ field }) => (
              <Field
                name={field.name}
                error={errors.theme}
                placeholder={t(
                  "creator_calendar__steptwo___service__form__placeholderone"
                )}
                title={t("creator_calendar__steptwo___service__form__labelone")}
              >
                <Select
                  {...field}
                  error={!!errors.theme}
                  items={formattedThemes}
                  id="consultation-form-setup-theme"
                  maxVisibleItems={5}
                  onSelectedItemChange={(changes) => {
                    field.onChange(changes.selectedItem?.value);
                    onBlur &&
                      onBlur({
                        theme: changes.selectedItem?.value as ConsultationTheme,
                      });
                    field.onBlur();
                  }}
                  selectedItem={defaultTheme}
                />
              </Field>
            )}
          />
        )}
        <Controller
          control={control}
          name="title"
          defaultValue=""
          render={({ field }) => (
            <Field
              data-testid="consultation-form-setup-title"
              name={field.name}
              error={errors.title}
              maxLength={MAX_TITLE_CHARACTERS}
              onBlur={(event) => {
                onBlur &&
                  onBlur({
                    title: event.target.value,
                  });
                field.onBlur();
              }}
              placeholder={
                isAstro
                  ? t("creator_calendar__steptwo___service__form__placeholder")
                  : t(
                      "NSFW_Live_1_1_creator_calendar__steptwo_service_form_text_name"
                    )
              }
              title={
                isAstro
                  ? t("creator_calendar__steptwo___service__form__label")
                  : t(
                      "NSFW_Live_1_1_creator_calendar__steptwo_service_form_title_label"
                    )
              }
            >
              <Input {...field} error={!!errors.title} />
            </Field>
          )}
        />
        <Controller
          control={control}
          name="caption"
          defaultValue=""
          render={({ field }) => (
            <Field
              {...field}
              data-testid="consultation-form-setup-caption"
              name={field.name}
              error={errors.caption}
              maxLength={MAX_CAPTION_CHARACTERS}
              onBlur={(event) => {
                onBlur &&
                  onBlur({
                    caption: event.target.value,
                  });
                field.onBlur();
              }}
              placeholder={
                isAstro
                  ? t(
                      "creator_calendar__steptwo___service__form__placeholdertwo"
                    )
                  : t(
                      "NSFW_Live_1_1_creator_calendar_steptwo_service_description_form__text"
                    )
              }
              defaultCount={field.value?.length || 0}
              title={
                isAstro
                  ? t("creator_calendar__steptwo___service__form__labelthree")
                  : t(
                      "NSFW_Live_1_1_creator_calendar__steptwo_service_description_label"
                    )
              }
              withCounter={true}
            >
              <Textarea error={!!errors.caption} />
            </Field>
          )}
        />
        {(!duration || defaultDuration) && (
          <Controller
            control={control}
            name="duration"
            defaultValue={null}
            render={({ field }) => (
              <Field
                name={field.name}
                error={errors.duration}
                placeholder={
                  isAstro
                    ? t(
                        "creator_calendar__steptwo___service__form__placeholderfour"
                      )
                    : t(
                        "NSFW_Live_1_1_creator_calendar_steptwo_service_form_text_choose_duration "
                      )
                }
                title={
                  isAstro
                    ? t("creator_calendar__steptwo___service__form__labelfour")
                    : t(
                        "NSFW_Live_1_1_creator_calendar_steptwo_service_form_label"
                      )
                }
              >
                <Select
                  error={!!errors.duration}
                  id="consultation-form-setup-duration"
                  items={formattedDurations}
                  maxVisibleItems={5}
                  onSelectedItemChange={(changes) => {
                    field.onChange(
                      parseInt(changes.selectedItem?.value as string)
                    );
                    onBlur &&
                      onBlur({
                        duration: parseInt(
                          changes.selectedItem?.value as string
                        ),
                      });
                    field.onBlur();
                  }}
                  selectedItem={defaultDuration}
                />
              </Field>
            )}
          />
        )}

        <Controller
          control={control}
          name="price"
          defaultValue={null}
          render={({ field }) => (
            <Field
              name={field.name}
              error={errors.price}
              onBlur={(e) => {
                e.target.value = parseFloat(e.target.value).toFixed(2);
                onBlur &&
                  onBlur({
                    price: parseFloat(e.target.value),
                  });
                field.onBlur();
              }}
            >
              <PriceInput
                error={!!errors.price}
                currency={currency}
                inputProps={{
                  ...field,
                  value: field.value ? `${field.value}`.replace(",", ".") : "",
                }}
                placeholder={
                  isAstro
                    ? t("creator_calendar__steptwo___service__form__labelfive")
                    : t(
                        "NSFW_Live_1_1_creator_calendar__steptwo__service_form_price"
                      )
                }
              />
            </Field>
          )}
        />
        {children}
      </form>
    </div>
  );
};
