import { useCallback, useState } from "react";

import { CREATOR_LIVE_REDIRECT } from "@/constants/routes";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Button, ButtonLink, Icon, Text, showToast } from "@leeloo/core";

import { BookingDTO } from "@/features/consultation/infrastructure/datasources/booking";
import ModalConsultation from "@/features/consultation/presentation/components/modal-consultation/ModalConsultation";
import { formatHumanLocalizedDate } from "@/features/consultation/presentation/utils/date";

import { useModalWithData } from "@/hooks/useModalWithData";

import { useDeleteBookingMutation } from "../../viewModel";
import { BookingCard } from "../booking-card";
import * as styles from "./bookings-dates-list.css";

interface BookingsDatesListProps {
  day: string;
  fetchBookings: (day: string) => void;
  past?: boolean;
  monthRange?: any;
}

export function BookingsDatesList({
  day,
  fetchBookings,
  past,
  monthRange,
}: BookingsDatesListProps) {
  const { t, i18n } = useTranslation();
  const { mutateAsync: deleteBookingMutation } = useDeleteBookingMutation();

  const [bookings, setBookings] = useState<any>();

  const onDelete = useCallback(async (id: string, date: string) => {
    try {
      await deleteBookingMutation({ id, day, monthRange });
      const bookings = await fetchBookings(day);

      setBookings(bookings);
      showToast({
        title: t("creator_service_consultation_deleted"),
        variant: "success",
      });
    } catch (error: any) {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    } finally {
      hideDeleteModal();
    }
  }, []);

  const [showDeleteModal, hideDeleteModal] = useModalWithData(
    (id: string) => () => {
      const booking = bookings?.find(
        (booking: BookingDTO) => booking.id === id
      );
      const creatorName = booking?.user?.username || ""; //TODO: check if this is the correct way to get the creator name
      const date = format(new Date(booking?.start), "yyyy-MM-dd");

      return (
        <ModalConsultation
          illustration="warning"
          title={t("creator_createservice__closemodal__title")}
          text={t("creator_booking__cancelconfirm__content__description", {
            username: creatorName,
          })}
          hideModal={() => hideDeleteModal()}
          mainButtonParams={{
            text: t("creator_service__delete__actions__button"),
            variant: "destructive",
            onAction: () => onDelete(id, date),
          }}
        />
      );
    }
  );

  return (
    <details
      className={styles.dayContainer}
      data-testid="bdl-day-container"
      onClick={async () => {
        const bookings = await fetchBookings(day);
        setBookings(bookings);
      }}
    >
      <summary data-testid="bdl-day-header" className={styles.dayHeader}>
        <Icon name="calendar" data-testid="bdl-day-header-icon-calendar" />
        <Text
          as="h4"
          data-testid="bdl-day-header-text"
          className={styles.dayHeaderTitle}
        >
          {day && formatHumanLocalizedDate(new Date(day), i18n.language)}
        </Text>
        <Icon
          name="chevron-down"
          data-testid="bdl-day-header-icon-chevron"
          className={styles.iconChevron}
        />
      </summary>
      <div data-testid="bookings-list">
        {bookings?.map((booking: BookingDTO, bookingIndex: number) => (
          <BookingCard
            key={bookingIndex}
            booking={booking}
            footerButtons={
              !past && (
                <>
                  <ButtonLink
                    data-testid="bc-delete-booking"
                    className={styles.buttonLink}
                    variant="secondary"
                    size="small"
                    onClick={() => showDeleteModal(booking.id)}
                  >
                    {t("cancel_button")}
                  </ButtonLink>
                  <Button
                    data-testid="bc-join-live"
                    leftIcon="live"
                    size="medium"
                    onPress={() => {
                      window.location.assign(CREATOR_LIVE_REDIRECT(booking.id));
                    }}
                  >
                    {t("creator_live__waitingroom__button")}
                  </Button>
                </>
              )
            }
            className={styles.bookingCard}
          />
        ))}
      </div>
    </details>
  );
}
