import { ConsultationTheme } from "@/features/consultation/domain/models/consultation";

export enum ConsultationStep {
  Configuration = "consultation_step_configuration",
  Calendar = "consultation_step_calendar",
  Confirmation = "consultation_step_confirmation",
}

export interface ConsultationState {
  id: string;
  theme?: ConsultationTheme;
  title: string;
  caption: string;
  duration: number | null;
  price: number | null;
  status: ConsultationStep;
}

export interface ConsultationActions {
  updateConsultation: (values: Partial<ConsultationState>) => void;
  resetStore: () => void;
}

export interface ConsultationSlice
  extends ConsultationState,
    ConsultationActions {}

export const store = (store: ConsultationSlice) => ({
  id: store.id,
  theme: store.theme,
  title: store.title,
  caption: store.caption,
  duration: store.duration,
  price: store.price,
  status: store.status,
  updateConsultation: store.updateConsultation,
  resetStore: store.resetStore,
});
