import { Loader } from "@leeloo/core";

import { useSynchronizeCalendarQuery } from "@/features/consultation/presentation/pages/OAuthCallback/viewModel";

const OAuthCallback = () => {
  useSynchronizeCalendarQuery();

  return (
    <Loader spin={true} data-testid="consultation-calendar-oauth-callback" />
  );
};

export default OAuthCallback;
