import { availabilitiesKey } from "@/constants/queryKeys";
import { CONSULTATION_CALENDAR_CALLBACK } from "@/constants/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { showToast } from "@leeloo/core";

import { CalendarId } from "@/features/consultation/domain/models/booking";
import { availabilityUseCases } from "@/features/consultation/domain/use-cases/availability";
import bookingUseCases from "@/features/consultation/domain/use-cases/booking";
import { AvailabilityDTO } from "@/features/consultation/infrastructure/datasources/availability";
import availabilityDataSourceImplementation from "@/features/consultation/infrastructure/datasources/availability";
import bookingDataSourceImplementation, {
  CalendarProviderAuthResponse,
  CalendarProviderResponse,
} from "@/features/consultation/infrastructure/datasources/booking";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";
import { UUID_NULL } from "@/features/consultation/presentation/utils/constants/uuid";
import { sortAvailabilitiesByDay } from "@/features/consultation/presentation/utils/date";

export const onSubmitAvailabilities = async (
  availabilities: AvailabilityDTO
) => {
  const { t } = useTranslation();
  const post = createAvailabilitiesMutation();
  const patch = updateAvailabilitiesMutation();

  try {
    if (availabilities.id === UUID_NULL) {
      return await post.mutateAsync(availabilities);
    }
    return await patch.mutateAsync(availabilities);
  } catch (error: any) {
    showToast({
      description: t("technical_error_toast"),
      title: t("technical_error_toast_title"),
      variant: "error",
    });
  }
};

export const useCalendarProvidersQuery = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);

  const { data, isLoading, error, isRefetching, refetch } = useQuery({
    queryKey: ["calendars-providers"],
    queryFn: () =>
      booking.getCalendarProviders<AxiosResponse<CalendarProviderResponse>>(),
    select: (data) => data.data,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
  };
};

export const useCalendarProvidersAuthMutation = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);
  const { t } = useTranslation();

  const { mutate, isSuccess, isError } = useMutation({
    mutationFn: (provider: CalendarId) =>
      booking.getCalendarProviderUrl<
        AxiosResponse<CalendarProviderAuthResponse>
      >({
        provider: provider,
        redirect_uri: `${window.location.origin}/app/catalog/${CONSULTATION_CALENDAR_CALLBACK}`,
      }),
    onSuccess: (response, provider) => {
      localStorage.setItem("provider", provider);
      window.location.assign(response.data.authorization_link);
    },
    onError: () => {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    },
  });

  return {
    getCalendarProviderUrl: (provider: CalendarId) => mutate(provider),
    isSuccess,
    isError,
  };
};

export const useUnsyncCalendarMutation = () => {
  const bookingSource = bookingDataSourceImplementation(httpImplementation());
  const booking = bookingUseCases(bookingSource);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutate, isError, isSuccess } = useMutation({
    mutationFn: (provider: CalendarId) =>
      booking.desynchroniseCalendar(provider),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["calendars-providers"],
      });
      showToast({
        title: t("creator_calendar__unsync__toast__title"),
        variant: "success",
      });
    },
    onError: () => {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    },
  });

  return {
    unSynchroniseCalendar: (provider: CalendarId) => mutate(provider),
    isError,
    isSuccess,
  };
};

export const createAvailabilitiesMutation = () => {
  const httpClient = httpImplementation();
  const availabilitiesDataSourceImpl =
    availabilityDataSourceImplementation(httpClient);
  const useCase = availabilityUseCases(availabilitiesDataSourceImpl);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: AvailabilityDTO) => {
      return useCase.createAvailabilities(params);
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: availabilitiesKey,
      });
    },
  });
};

export const updateAvailabilitiesMutation = () => {
  const httpClient = httpImplementation();
  const availabilitiesDataSourceImpl =
    availabilityDataSourceImplementation(httpClient);
  const useCase = availabilityUseCases(availabilitiesDataSourceImpl);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: AvailabilityDTO) => {
      return useCase.updateAvailabilities<AxiosResponse<AvailabilityDTO>>(
        params
      );
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: availabilitiesKey,
      });
    },
  });
};

export const useSubmitAvailabilities = () => {
  const { t } = useTranslation();
  const post = createAvailabilitiesMutation();
  const patch = updateAvailabilitiesMutation();
  return {
    onSubmit: async (availabilities: AvailabilityDTO) => {
      try {
        const response =
          availabilities.id === UUID_NULL
            ? await post.mutateAsync(availabilities)
            : await patch.mutateAsync(availabilities);
        return response;
      } catch (error: any) {
        showToast({
          description: t("technical_error_toast"),
          title: t("technical_error_toast_title"),
          variant: "error",
        });
      }
    },
  };
};

export const useAvailabilitiesQuery = () => {
  const httpClient = httpImplementation();
  const availabilitiesDataSourceImpl =
    availabilityDataSourceImplementation(httpClient);
  const useCase = availabilityUseCases(availabilitiesDataSourceImpl);
  const { i18n } = useTranslation();

  const timezone = new Intl.DateTimeFormat(i18n.language).resolvedOptions()
    .timeZone;

  return useQuery({
    queryKey: availabilitiesKey,
    queryFn: () =>
      useCase.getAvailabilities<AxiosResponse<AvailabilityDTO>>(timezone),
    select: (data) => {
      return sortAvailabilitiesByDay(data.data);
    },
  });
};
