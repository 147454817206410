import { useContext } from "react";

import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Text, Title } from "@leeloo/core";

import * as styles from "./sidebar-calendar.css";

export default function SidebarCalendar() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;

  return (
    <Heading data-testid="sidebar-calendar">
      <Illustration
        name="live-alt"
        data-testid="sidebar-calendar-illustration"
      />
      <Title centered={true} as="h3" data-testid="sidebar-calendar-title">
        {t("creator_calendar__sync__header__title__availability")}
      </Title>
      <Text className={styles.text} data-testid="sidebar-calendar-text">
        {t("creator_calendar__sync__description__availability")}
      </Text>
    </Heading>
  );
}
