import { RudderAnalytics } from "@rudderstack/analytics-js";

import { TrackingService } from "@/features/consultation/infrastructure/services/tracking";

export const analytics = new RudderAnalytics();

export const trackingImplementation: TrackingService = {
  page_view: (name) => {
    return analytics.page(name);
  },
  track: (event, properties) => {
    return analytics.track(event, properties);
  },
};
