import {
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
  CREATOR_PROFILE,
} from "@/constants/routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Cartridge, Loader, Text, Title } from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import {
  Calendar,
  CalendarId,
  CalendarStatus,
} from "@/features/consultation/domain/models/booking";
import { CalendarSync } from "@/features/consultation/presentation/components/calendar-sync/CalendarSync";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import FormFooter from "@/features/consultation/presentation/components/form-footer/FormFooter";

import * as styles from "./calendar-setup.css";
import {
  useCalendarProvidersAuthMutation,
  useCalendarProvidersQuery,
  useUnsyncCalendarMutation,
} from "./viewModel";

export function CalendarSetup() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: providers,
    error,
    isLoading,
    refetch,
    isRefetching,
  } = useCalendarProvidersQuery();

  const calendar =
    (providers &&
      providers.providers.find(
        (provider: Calendar) => provider.status === CalendarStatus.SYNCED
      )) ||
    null;

  const { getCalendarProviderUrl } = useCalendarProvidersAuthMutation();
  const { unSynchroniseCalendar } = useUnsyncCalendarMutation();

  if (isLoading || isRefetching) {
    return (
      <Loader spin={true} className={styles.loader} data-testid="cs-loader" />
    );
  }

  if (error) {
    return <Error onClick={() => refetch()} data-testid="cs-error" />;
  }

  return (
    <>
      <MobileContentHeader
        withCloseIcon
        onCloseIconClick={() => window.location.assign(CREATOR_PROFILE)}
        title={t("creator_calendar__stepone___sync__header__title")}
        data-testid="cs-header-mobile"
      />
      <ContentHeader
        title={t("creator_calendar__stepone___sync__heading__title")}
        data-testid="cs-header-desktop"
      />
      <div className={styles.calendarSetup} data-testid="calendar-setup">
        <Title as="h3" className={styles.title} data-testid="cs-title">
          {t("creator_calendar__stepone___sync__heading__title")}
        </Title>
        <Text className={styles.text} data-testid="cs-text">
          {t("creator_calendar__stepone___sync__heading__description")}
        </Text>
        <Cartridge
          data-testid="cs-cartridge"
          title={t("creator_calendar__cartouche__title")}
          description={t("creator_calendar__cartouche__description")}
          className={styles.cartridge}
          buttons={[
            <Button
              size="small"
              variant="tertiary"
              key="button_calendar"
              onPress={() =>
                window.open(
                  "https://www.youtube.com/watch?v=bQiuj0m3CT8&ab_channel=MYM",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              {t("creator_calendar__cartouche__button")}
            </Button>,
          ]}
        />
        {providers && (
          <CalendarSync
            calendar={calendar}
            className={styles.calendarSynced}
            data-testid="cs-calendar-synced"
            unSynchroniseCalendar={(provider: CalendarId) =>
              unSynchroniseCalendar(provider)
            }
            getCalendarProviderUrl={(provider: CalendarId) =>
              getCalendarProviderUrl(provider)
            }
          />
        )}
        <FormFooter
          disabled={!calendar}
          data-testid="cs-footer"
          submitLabel={t("next_button")}
          onClick={() =>
            navigate(`/catalog/${CONSULTATION_AVAILABILITY_SETUP}`)
          }
        />
      </div>
    </>
  );
}
