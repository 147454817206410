import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { Consultation } from "@/features/consultation/domain/models/consultation";
import {
  ConsultationAstroParams,
  ConsultationMutationParams,
} from "@/features/consultation/domain/repositories/consultation";
import { consultationUseCases } from "@/features/consultation/domain/use-cases/consultation";
import creatorUseCases from "@/features/consultation/domain/use-cases/creator/creator";
import { consultationDataSourceImplementation } from "@/features/consultation/infrastructure/datasources/consultation";
import {
  CreatorDTO,
  creatorDataSourceImplementation,
} from "@/features/consultation/infrastructure/datasources/creator/creatorDataSourceImplementation";
import {
  ConsultationState,
  store,
} from "@/features/consultation/infrastructure/datastores/store/store";
import { storeImplementation } from "@/features/consultation/infrastructure/datastores/store/storeImplementation";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useStore = () => {
  const storeImpl = store(storeImplementation());

  return {
    theme: storeImpl.theme,
    title: storeImpl.title,
    caption: storeImpl.caption,
    duration: storeImpl.duration,
    price: storeImpl.price,
    status: storeImpl.status,
    updateConsultation: (values: Partial<ConsultationState>) =>
      storeImpl.updateConsultation(values),
    resetStore: () => storeImpl.resetStore(),
  };
};

export const useCreatorMeQuery = () => {
  const httpClient = httpImplementation();
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient);
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-me"],
    queryFn: () => useCase.getCreator<AxiosResponse<CreatorDTO>>(),
    select: (data) => data.data,
    notifyOnChangeProps: ["data"],
  });
};

export const useCreateConsultationMutation = () => {
  const httpClient = httpImplementation();
  const setupDataSourceImpl = consultationDataSourceImplementation(httpClient);
  const useCaseSetup = consultationUseCases(setupDataSourceImpl);
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["consultations"],
    mutationFn: (params: ConsultationMutationParams) => {
      const { theme, title, caption, price, duration, type, isAstro } = params;
      const payloadAstro = {
        theme,
        title,
        caption,
        price,
        duration,
        type,
      };

      const payload = {
        title,
        caption,
        price,
        duration,
        type,
      } as ConsultationAstroParams;

      if (isAstro) {
        return useCaseSetup.createConsultationAstro<
          AxiosResponse<Consultation>
        >(payloadAstro);
      }
      return useCaseSetup.createConsultation<AxiosResponse<Consultation>>(
        payload
      );
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["consultations"],
      });
    },
  });
};
