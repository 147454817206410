export const MIN_TITLE_CHARACTERS = 1;
export const MAX_TITLE_CHARACTERS = 50;
export const MIN_CAPTION_CHARACTERS = 1;
export const MAX_CAPTION_CHARACTERS = 1500;
export const CONSULTATION_DURATIONS = [
  900, 1800, 2700, 3600, 4500, 5400, 6300, 7200,
];
export const MAX_PRICE_VALUE = 100000;
export const MIN_PRICE_VALUE = 2000;
export const MAX_PRESTATIONS = 10;
export const MAX_DISPLAYED_BOOKINGS = 5;
export const MAX_DISPLAYED_MONTH = new Date().getMonth() + 2;
