import {
  HTMLAttributes,
  type InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import i18n from "i18next";

import { Text } from "@leeloo/core";

import * as styles from "./price-input.css";

export interface FormPriceProps extends HTMLAttributes<HTMLDivElement> {
  inputProps: InputHTMLAttributes<HTMLInputElement>;
  disabled?: boolean;
  error?: boolean;
  currency: string;
  placeholder: string;
}

export const PriceInput = ({
  disabled = false,
  error,
  inputProps,
  className,
  currency,
  placeholder,
  ...props
}: FormPriceProps) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const currencySymbol = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: currency,
  })
    .formatToParts(1)
    .find((x) => x.type === "currency")?.value;

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);

      if (typeof inputProps.onFocus === "function") {
        inputProps.onFocus(event);
      }
    },
    [inputProps.onFocus]
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      if (typeof inputProps.onBlur === "function") {
        inputProps.onBlur(event);
      }
    },
    [inputProps.onBlur]
  );

  return (
    <div
      className={clsx(styles.element({ error, disabled, focused }), className)}
      data-testid="price-input"
      onBlur={onBlur}
      onFocus={onFocus}
      onClick={() => inputRef?.current?.focus()}
    >
      <Text className={styles.placeholder} children={placeholder} />
      <div {...props} className={styles.inputContainer}>
        <input
          {...inputProps}
          ref={inputRef}
          className={styles.input}
          type="text"
          value={inputProps.value || ""}
          pattern="^[0-9\.]*$"
          inputMode="decimal"
          placeholder="______"
          data-testid={"price-input-field"}
        />

        <label htmlFor={inputProps.name} data-testid="price-input-label">
          {currencySymbol}
        </label>
      </div>
    </div>
  );
};

export default PriceInput;
