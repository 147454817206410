import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import { Consultation } from "@/features/consultation/domain/models/consultation";
import { ConsultationParams } from "@/features/consultation/domain/repositories/consultation";
import { consultationUseCases } from "@/features/consultation/domain/use-cases/consultation";
import creatorUseCases from "@/features/consultation/domain/use-cases/creator/creator";
import { consultationDataSourceImplementation } from "@/features/consultation/infrastructure/datasources/consultation";
import {
  CreatorDTO,
  creatorDataSourceImplementation,
} from "@/features/consultation/infrastructure/datasources/creator/creatorDataSourceImplementation";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";

export const useCreatorMeQuery = () => {
  const httpClient = httpImplementation();
  const creatorDataSourceImpl = creatorDataSourceImplementation(httpClient);
  const useCase = creatorUseCases(creatorDataSourceImpl);

  return useQuery({
    queryKey: ["creator-me"],
    queryFn: () => useCase.getCreator<AxiosResponse<CreatorDTO>>(),
    select: (data) => data.data,
    notifyOnChangeProps: ["data"],
  });
};

export const useGetConsultationByIdQuery = () => {
  const urlParams = useParams();
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCase = consultationUseCases(consultationDataSourceImpl);

  return useQuery({
    queryKey: ["consultations", urlParams.id],
    queryFn: () => {
      return useCase.getConsultationById<AxiosResponse<Consultation>>(
        urlParams.id as string
      );
    },
    select: (data) => {
      const consultation = {
        ...data.data,
        price: {
          currency: data.data.price.currency,
          value: data.data.price.value / 100,
        },
      };
      return consultation;
    },
  });
};

export const useUpdateConsultation = () => {
  const urlParams = useParams();
  const httpClient = httpImplementation();
  const consultationDataSourceImpl =
    consultationDataSourceImplementation(httpClient);
  const useCase = consultationUseCases(consultationDataSourceImpl);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: { payload: Partial<ConsultationParams> }) => {
      return useCase.updateConsultation<AxiosResponse<Consultation>>(
        params.payload,
        urlParams.id as string
      );
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ["consultations"],
        exact: true,
      });
    },
  });
};
