import { availabilitiesKey } from "@/constants/queryKeys";
import { CONSULTATION_CONFIGURATION_CREATION } from "@/constants/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { showToast } from "@leeloo/core";

import { availabilityUseCases } from "@/features/consultation/domain/use-cases/availability";
import { AvailabilityDTO } from "@/features/consultation/infrastructure/datasources/availability";
import availabilityDataSourceImplementation from "@/features/consultation/infrastructure/datasources/availability";
import httpImplementation from "@/features/consultation/infrastructure/services/httpImplementation";
import { UUID_NULL } from "@/features/consultation/presentation/utils/constants/uuid";
import { sortAvailabilitiesByDay } from "@/features/consultation/presentation/utils/date";

export const createAvailabilitiesMutation = () => {
  const httpClient = httpImplementation();
  const availabilitiesDataSourceImpl =
    availabilityDataSourceImplementation(httpClient);
  const useCase = availabilityUseCases(availabilitiesDataSourceImpl);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: AvailabilityDTO) => {
      return useCase.createAvailabilities(params);
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: availabilitiesKey,
      });
    },
  });
};

export const updateAvailabilitiesMutation = () => {
  const httpClient = httpImplementation();
  const availabilitiesDataSourceImpl =
    availabilityDataSourceImplementation(httpClient);
  const useCase = availabilityUseCases(availabilitiesDataSourceImpl);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: AvailabilityDTO) => {
      return useCase.updateAvailabilities<AxiosResponse<AvailabilityDTO>>(
        params
      );
    },
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: availabilitiesKey,
      });
    },
  });
};

export const useSubmitAvailabilities = () => {
  const { t } = useTranslation();
  const post = createAvailabilitiesMutation();
  const patch = updateAvailabilitiesMutation();
  const navigate = useNavigate();

  return {
    onSubmit: async (availabilities: AvailabilityDTO) => {
      try {
        const response =
          availabilities.id === UUID_NULL
            ? await post.mutateAsync(availabilities)
            : await patch.mutateAsync(availabilities);
        navigate(`/catalog/${CONSULTATION_CONFIGURATION_CREATION}`);
        return response;
      } catch (error: any) {
        showToast({
          description: t("technical_error_toast"),
          title: t("technical_error_toast_title"),
          variant: "error",
        });
      }
    },
  };
};

export const useAvailabilitiesQuery = () => {
  const httpClient = httpImplementation();
  const availabilitiesDataSourceImpl =
    availabilityDataSourceImplementation(httpClient);
  const useCase = availabilityUseCases(availabilitiesDataSourceImpl);
  const { i18n } = useTranslation();

  const timezone = new Intl.DateTimeFormat(i18n.language).resolvedOptions()
    .timeZone;

  return useQuery({
    queryKey: availabilitiesKey,
    queryFn: () =>
      useCase.getAvailabilities<AxiosResponse<AvailabilityDTO>>(timezone),
    select: (data) => {
      return sortAvailabilitiesByDay(data.data);
    },
  });
};
