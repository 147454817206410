import {
  Availabilities,
  Availability,
  AvailabilityHour,
  MAX_AVAILABILITIES,
  MIN_AVAILABILITIES,
} from "@/features/consultation/domain/models/availability";
import { AvailabilityRepository } from "@/features/consultation/domain/repositories/availability";

const sortHours = (
  hours: AvailabilityHour[] | undefined,
): AvailabilityHour[] => {
  if (!hours) {
    return [];
  }
  return hours.sort((a, b) => a.start - b.start);
};

export const checkOverlappingRange = (ranges: Availability[]) => {
  for (let i = 0; i < ranges.length; i++) {
    if (ranges[i].enabled) {
      const sortedHours = sortHours(ranges[i].hours);

      for (let j = 0; j < sortedHours.length - 1; j++) {
        const currentRange = sortedHours[j];
        const nextRange = sortedHours[j + 1];

        // Check for overlapping condition
        if (
          nextRange.start < currentRange.end ||
          (nextRange.start > currentRange.start &&
            nextRange.start < currentRange.end)
        ) {
          return true;
        }
      }
    }
  }
  return false;
};
export const availabilityUseCases = (repository: AvailabilityRepository) => ({
  getAvailabilities: <T>(timezone: string) => {
    try {
      return repository.getAvailabilities<T>(timezone);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  createAvailabilities: async (params: Availabilities) => {
    try {
      const daysSet = new Set<number>();

      if (!("enabled" in params)) {
        throw Error("Enabled is required");
      }

      if (!params.availabilities) {
        throw Error("Availabilities are required");
      }

      if (params.availabilities.length !== 7) {
        throw Error("Availabilities should be 7 days long");
      }

      params.availabilities.forEach((availability, index) => {
        if (
          availability.enabled === null ||
          availability.enabled === undefined
        ) {
          throw Error(
            `Availability for day ${index} must have the 'enabled' property`,
          );
        }

        if (availability.enabled) {
          if (availability.day < 0 || availability.day > 6) {
            throw new Error("Each availability day must be between 0 and 6");
          }

          if (
            availability.hours.length < MIN_AVAILABILITIES ||
            availability.hours.length > MAX_AVAILABILITIES
          ) {
            throw new Error(
              `Availability for day ${availability.day} must have between 1 and 10 hours`,
            );
          }

          availability.hours.forEach((hour) => {
            if (!hour.start && hour.start !== 0) {
              throw new Error(
                `Availability for day ${availability.day} must have a 'start' time for each hour`,
              );
            }
            if (!hour.end && hour.end !== 0) {
              throw new Error(
                `Availability for day ${availability.day} must have an 'end' time for each hour`,
              );
            }
            if (hour.end <= hour.start) {
              throw new Error(`End time should be greater than start time`);
            }
          });
        }

        daysSet.add(availability.day);
      });

      if (params.enabled && checkOverlappingRange(params.availabilities)) {
        throw Error(`Ranges are overlapping`);
      }

      if (daysSet.size !== 7) {
        throw new Error(
          "Availabilities must contain each day from 0 to 6 exactly once",
        );
      }

      return await repository.createAvailabilities(params);
    } catch (error: any) {
      throw new Error(error);
    }
  },

  updateAvailabilities: async <T>(params: Availabilities) => {
    try {
      const daysSet = new Set<number>();

      if (!("enabled" in params) || params.enabled === undefined) {
        throw Error("Enabled is required");
      }

      if (!params.availabilities) {
        throw Error("Availabilities are required");
      }

      if (params.availabilities.length !== 7) {
        throw Error("Availabilities should be 7 days long");
      }

      params.availabilities.forEach((availability, index) => {
        if (
          availability.enabled === null ||
          availability.enabled === undefined
        ) {
          throw Error(
            `Availability for day ${index} must have the 'enabled' property`,
          );
        }

        if (availability.enabled) {
          if (availability.day < 0 || availability.day > 6) {
            throw new Error("Each availability day must be between 0 and 6");
          }

          if (
            availability.hours.length < MIN_AVAILABILITIES ||
            availability.hours.length > MAX_AVAILABILITIES
          ) {
            throw new Error(
              `Availability for day ${availability.day} must have between 1 and 10 hours`,
            );
          }

          availability.hours.forEach((hour) => {
            if (!hour.start && hour.start !== 0) {
              throw new Error(
                `Availability for day ${availability.day} must have a 'start' time for each hour`,
              );
            }
            if (!hour.end && hour.end !== 0) {
              throw new Error(
                `Availability for day ${availability.day} must have an 'end' time for each hour`,
              );
            }
            if (hour.end <= hour.start) {
              throw new Error(`End time should be greater than start time`);
            }
          });
        }

        daysSet.add(availability.day);
      });

      if (params.enabled && checkOverlappingRange(params.availabilities)) {
        throw Error(`Ranges are overlapping`);
      }

      if (daysSet.size !== 7) {
        throw new Error(
          "Availabilities must contain each day from 0 to 6 exactly once",
        );
      }
      return await repository.updateAvailabilities<T>(params);
    } catch (error: any) {
      throw new Error(error);
    }
  },
});
