import { useEffect, useState } from "react";

import { CREATOR_PROFILE } from "@/constants/routes";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { Button, Loader, showToast } from "@leeloo/core";

import {
  MAX_DISPLAYED_BOOKINGS,
  MAX_DISPLAYED_MONTH,
} from "@/features/consultation/domain/constants";
import { BookingDatesDTO } from "@/features/consultation/infrastructure/datasources/booking";
import EmptyList from "@/features/consultation/presentation/components/empty-list/EmptyList";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import { useBookingsByDateQuery } from "@/features/consultation/presentation/pages/Bookings/viewModel";
import { isCurrentMonth } from "@/features/consultation/presentation/utils/date";

import { BookingsCalendarHead } from "../bookings-calendar-head/BookingsCalendarHead";
import { BookingsDatesList } from "../bookings-dates-list/BookingsDatesList";
import * as styles from "./bookings-panel.css";

interface BookingsPanelProps {
  bookingsDates?: BookingDatesDTO;
  isLoading: boolean;
  isError: boolean;
  refetchBookingDates: () => void;
  selectedMonth: Date;
  setSelectedMonth: (selectedMonth: Date) => void;
  past?: boolean;
  monthRange?: any;
}

export function BookingsPanel({
  bookingsDates,
  isLoading,
  isError,
  refetchBookingDates,
  selectedMonth,
  setSelectedMonth,
  past,
}: BookingsPanelProps) {
  const { t } = useTranslation();

  const [maxBookings, setMaxBookings] = useState(MAX_DISPLAYED_BOOKINGS);

  useEffect(() => {
    setSelectedMonth(new Date());
  }, []);

  const { getBookingsByDate } = useBookingsByDateQuery();

  const fetchBookings = async (day: string) => {
    try {
      return await getBookingsByDate(format(new Date(day), "yyyy-MM-dd"));
    } catch (error) {
      showToast({
        description: t("technical_error_toast"),
        title: t("technical_error_toast_title"),
        variant: "error",
      });
    }
  };

  const dates = bookingsDates?.slice(0, maxBookings);

  return (
    <>
      <BookingsCalendarHead
        disabledLeft={!past && isCurrentMonth(selectedMonth)}
        disabledRight={
          past
            ? isCurrentMonth(selectedMonth)
            : selectedMonth.getMonth() === MAX_DISPLAYED_MONTH
        }
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        data-testid="bookings-calendar-head"
      />
      {isLoading ? (
        <Loader
          spin={true}
          className={styles.smallLoader}
          data-testid="bookings-dates-loader"
        />
      ) : isError ? (
        <Error
          onClick={() => refetchBookingDates()}
          data-testid="bookings-dates-error"
        />
      ) : bookingsDates?.length ? (
        <div className={styles.datesList} data-testid="b-dates-list">
          {dates?.map((day: string, index: number) => {
            return (
              <BookingsDatesList
                day={day}
                fetchBookings={fetchBookings}
                key={index}
                past={past}
              />
            );
          })}
          {bookingsDates && dates && dates.length < bookingsDates.length && (
            <Button
              leftIcon="add"
              variant="secondary"
              className={styles.showMoreButton}
              onPress={() =>
                setMaxBookings(maxBookings + MAX_DISPLAYED_BOOKINGS)
              }
              data-testid="button-more"
            >
              {t("profile_see_more_cta")}
            </Button>
          )}
        </div>
      ) : (
        <EmptyList
          illustration="empty-state"
          title={t("creator_mybooking__empty__title")}
          text={t("creator_mybooking__empty__description")}
          data-testid="bookings-empty-list"
          children={
            <Button
              className={styles.actionButton}
              data-testid="cl-empty-add-button"
              onPress={() => window.location.assign(CREATOR_PROFILE)}
            >
              {t("profile_return_profile_button")}
            </Button>
          }
        />
      )}
    </>
  );
}
