import { ConsultationTheme } from "@/features/consultation/domain/models/consultation";

export const illustrationMap: Record<ConsultationTheme, string> = {
  [ConsultationTheme.ASTROLOGY]: "astro-astrology",
  [ConsultationTheme.TAROLOGY]: "astro-tarology",
  [ConsultationTheme.ENERGY_SPECIALIST]: "astro-energyspecialist",
  [ConsultationTheme.HOLISTIC_SPECIALIST]: "astro-holisticspecialist",
  [ConsultationTheme.MEDIUM_CLAIRVOYANCE]: "astro-mediumclairvoyance",
  [ConsultationTheme.NUMEROLOGY]: "astro-numerology",
  [ConsultationTheme.OTHERS]: "astro-generic",
};
