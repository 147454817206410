import { useContext } from "react";

import {
  CALENDAR,
  CONSULTATION_AVAILABILITY_SETUP,
  CONSULTATION_CONFIGURATION_CREATION,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { useTranslation } from "react-i18next";

import { Heading, Illustration, Progression, Title } from "@leeloo/core";

export default function SidebarConfirmation() {
  const { t } = useTranslation();
  const { isAstro } = useContext(Context).creator;

  return (
    <>
      <Heading data-testid="sidebar-confirmation">
        <Illustration
          name="live-alt"
          data-testid="sidebar-confirmation-illustration"
        />
        <Title centered={true} as="h3" data-testid="sidebar-confirmation-title">
          {isAstro
            ? t("creator_consultations__sidebar__title_desktop")
            : t("NSFW_Live_1_1_creator_consultations__sidebar__title_desktop")}
        </Title>
      </Heading>
      <Progression
        data-testid="sidebar-confirmation-progression"
        header={{
          icon: "push",
          title: isAstro
            ? t("creator_consultations__sidebar__titletwo_desktop")
            : t(
                "NSFW_Live_1_1_creator_consultations__sidebar__titletwo_desktop"
              ),
        }}
        steps={[
          {
            label: t("creator_consultations__sidebar__text_desktop"),
            status: "success",
            link: CALENDAR,
          },
          {
            label: t("creator_consultations__sidebar_timeslots"),
            status: "success",
            link: CONSULTATION_AVAILABILITY_SETUP,
          },
          {
            label: isAstro
              ? t("creator_consultations__sidebar__texttwo_desktop")
              : t(
                  "NSFW_Live_1_1_creator_consultations__sidebar__texttwo_desktop"
                ),
            status: "success",
            link: CONSULTATION_CONFIGURATION_CREATION,
          },
        ]}
      />
    </>
  );
}
