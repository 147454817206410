import { useCallback, useContext } from "react";

import {
  CALENDAR,
  CONSULTATIONS_LIST,
  CONSULTATION_CALENDAR_SETUP,
  CONSULTATION_CREATION,
  CREATOR_PROFILE,
} from "@/constants/routes";
import { Context } from "@/contexts/creator/Creator";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  ButtonIcon,
  Cartridge,
  Heading,
  IllustrationName,
  Loader,
  Text,
  Title,
  showToast,
} from "@leeloo/core";

import { ContentHeader, MobileContentHeader } from "@/components/layout/Layout";

import { MAX_PRESTATIONS } from "@/features/consultation/domain/constants";
import {
  Calendar,
  CalendarId,
  CalendarStatus,
} from "@/features/consultation/domain/models/booking";
import { trackingImplementation } from "@/features/consultation/infrastructure/services/trackingImplementation";
import { CalendarSync } from "@/features/consultation/presentation/components/calendar-sync/CalendarSync";
import Card from "@/features/consultation/presentation/components/card";
import { Error } from "@/features/consultation/presentation/components/error/Error";
import ModalConsultation from "@/features/consultation/presentation/components/modal-consultation/ModalConsultation";
import { illustrationMap } from "@/features/consultation/presentation/utils/constants/illustrations";

import { useModalWithData } from "@/hooks/useModalWithData";

import * as styles from "./confirmation.css";
import {
  useCalendarProvidersQuery,
  useConsultationByIdQuery,
  useConsultationsQuery,
  useDeleteConsultationMutation,
  useUnsyncCalendarMutation,
} from "./viewModel";

export const Confirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAstro } = useContext(Context).creator;
  const trackImpl = trackingImplementation;

  const {
    data: consultation,
    isLoading: isLoadingConsultation,
    isError: isErrorConsultation,
    refetch: refetchConsultation,
    isRefetching: isRefetchingConsultation,
  } = useConsultationByIdQuery();
  const {
    data: consultations,
    isLoading: isLoadingConsultations,
    isError: isErrorConsultations,
    isRefetching: isRefetchingConsultations,
  } = useConsultationsQuery();
  const { mutateAsync: deleteConsultationMutation, isPending } =
    useDeleteConsultationMutation();
  const { data: providers, isLoading: isLoadingCalendarsProviders } =
    useCalendarProvidersQuery();
  const { unSynchroniseCalendar } = useUnsyncCalendarMutation();

  const calendar =
    providers?.providers.find(
      (provider: Calendar) => provider.status === CalendarStatus.SYNCED
    ) || null;

  const onCreateConsultation = () => {
    trackImpl.track("creator_track_add_new_booking", "");
    if (consultations && consultations.length < MAX_PRESTATIONS) {
      if (calendar)
        navigate(`/catalog/${CONSULTATION_CREATION}`, {
          replace: true,
        });
      else navigate(`/catalog/${CONSULTATION_CALENDAR_SETUP}`);
    } else
      showToast({
        title: t("creator_service_maximum_reached"),
        variant: "error",
      });
  };

  const onDeleteConsultation = useCallback(
    async (id: string) => {
      try {
        await deleteConsultationMutation(id);
        navigate(`/catalog/${CONSULTATIONS_LIST}`);
      } catch (error: any) {
        showToast({
          description: t("technical_error_toast"),
          title: t("technical_error_toast_title"),
          variant: "error",
        });
      } finally {
        hideDeleteConsultationModal();
      }
    },
    [isPending]
  );

  const [showDeleteConsultationModal, hideDeleteConsultationModal] =
    useModalWithData((id: string) => () => {
      return (
        <ModalConsultation
          illustration="warning"
          title={t("creator_createservice__closemodal__title")}
          text={t("creator_service__delete__content__description")}
          hideModal={() => hideDeleteConsultationModal()}
          mainButtonParams={{
            text: t("creator_service__delete__actions__button"),
            variant: "destructive",
            onAction: () => onDeleteConsultation(id),
          }}
        />
      );
    });

  if (
    isLoadingConsultation ||
    isRefetchingConsultation ||
    isLoadingConsultations ||
    isRefetchingConsultations ||
    isLoadingCalendarsProviders
  ) {
    return <Loader className={styles.loader} spin={true} />;
  }

  return (
    <>
      <ContentHeader
        title={
          isAstro
            ? t("creator_calendar__stepthree___summary__heading__title")
            : t(
                "NSFW_Live_11_creator_calendar__steptwo___service__header__title"
              )
        }
        data-testid="cc-header-desktop"
      />
      <MobileContentHeader
        title={
          isAstro
            ? t("creator_calendar__steptwo___service__header__title")
            : t(
                "NSFW_Live_11_creator_calendar__steptwo___service__header__title"
              )
        }
        data-testid="cc-header-mobile"
      />
      <div
        className={styles.confirmation}
        data-testid="consultation-confirmation"
      >
        <Heading className={styles.heading}>
          <Title as="h3" className={styles.title} data-testid="cc-title">
            {isAstro
              ? t("creator_calendar__stepthree___summary__heading__title")
              : t(
                  "NSFW_Live_creator_calendar_stepthree_summary__heading_title"
                )}
          </Title>
          <Text data-testid="cc-text">
            <Trans
              i18nKey={
                isAstro
                  ? "creator_calendar__stepthree___summary__heading__description"
                  : "NSFW_Live_creator_calendar__stepthree___summary__heading__description"
              }
              components={[
                <a href="" target="_blank" rel="noreferrer">
                  _
                </a>,
                <a href="" target="_blank" rel="noreferrer">
                  _
                </a>,
              ]}
            />
          </Text>
        </Heading>
        {providers && calendar ? (
          <CalendarSync
            calendar={calendar}
            className={styles.calendarSync}
            getCalendarProviderUrl={() => useCalendarProvidersQuery()}
            unSynchroniseCalendar={(id: CalendarId) =>
              unSynchroniseCalendar(id)
            }
          />
        ) : (
          <Cartridge
            type="danger"
            className={styles.calendarSync}
            title={t("creator_service__nocalendar__cartouche_title")}
            description={t(
              "creator_service__nocalendar__cartouche_description"
            )}
            buttons={[
              <Button
                key="calendar"
                theme="inverted"
                variant="tertiary"
                size="small"
                onPress={() => navigate(`/catalog/${CALENDAR}`)}
              >
                {t("creator_service__nocalendar__cartouche_button")}
              </Button>,
            ]}
          />
        )}
        {!consultation ||
        !consultation.id ||
        isErrorConsultation ||
        isErrorConsultations ? (
          <Error
            data-testid="consultation-creation-error"
            onClick={() => refetchConsultation()}
          />
        ) : (
          <div className={styles.consultationsList}>
            <Card
              className={styles.card}
              key={consultation.id}
              consultation={consultation}
              withClamp
              illustrationName={
                (consultation.theme &&
                  (illustrationMap[consultation.theme] as IllustrationName)) ||
                ("cartridge-informative" as IllustrationName)
              }
              children={[
                <ButtonIcon
                  iconName="visibility-on"
                  size="medium"
                  key="button-visibility"
                  variant="secondary"
                  data-testid="card-view-button"
                  children="Réservations"
                  onPress={() =>
                    navigate(`/catalog/consultations/${consultation.id}`, {
                      replace: true,
                    })
                  }
                />,
                <ButtonIcon
                  iconName="delete"
                  size="medium"
                  key="button-delete"
                  variant="destructive"
                  data-testid="card-delete-button"
                  onPress={() => showDeleteConsultationModal(consultation.id)}
                  children="Supprimer"
                />,
                <Button
                  size="medium"
                  leftIcon="edit-content"
                  key="button-edit"
                  children={t(
                    "profile_configuration_picture_preview_modal_modify_button"
                  )}
                  className={styles.editButton}
                  data-testid="card-edit-button"
                  onPress={() =>
                    navigate(
                      `/catalog/consultations/edit/${consultation?.id}`,
                      {
                        replace: true,
                      }
                    )
                  }
                />,
              ]}
            />
            <div className={styles.emptyCard} />
          </div>
        )}
        <Button
          leftIcon="add"
          className={styles.actionButton}
          data-testid="cc-add-button"
          onPress={() => onCreateConsultation()}
        >
          {isAstro
            ? t("creator_service_empty__actions__button")
            : t("NSFW_LIVE_1_1_create_new_video_call_button")}
        </Button>
        <Button
          variant="secondary"
          className={styles.actionButton}
          data-testid="cc-return-profile-button"
          onPress={() => window.location.assign(CREATOR_PROFILE)}
        >
          {t("profile_return_profile_button")}
        </Button>
      </div>
    </>
  );
};
